import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { gridClasses } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium/DataGridPremium';
import { stringDateColumn } from '../../../components/DataGrid/stringDateColumn';
import { PaperworkStatusChip } from '../../../components/DataGrid/usePaperworkStatusColumn';
import { DetailSection } from '../../../components/DetailSection';
import { ChevronRightIcon } from '../../../components/Icons';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';

function PaperworkLinkedTableComponent() {
  const { paperwork } = usePaperwork();
  const navigate = useNavigate();

  if (
    !paperwork ||
    !paperwork.linkedPaperworks ||
    (paperwork.linkedPaperworks.children.length === 0 && paperwork.linkedPaperworks.parents.length === 0)
  )
    return <></>;

  return (
    <DetailSection sectionTitle={'Pratiche Collegate'} disabled={false}>
      <Paper sx={{ overflow: 'auto' }}>
        <DataGridPremium
          density="compact"
          rows={[...paperwork.linkedPaperworks.parents, ...paperwork.linkedPaperworks.children]}
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 100,
              type: 'number',
            },
            {
              ...stringDateColumn,
              field: 'releaseDate',
              headerName: 'Data rialscio',
              width: 50,
            },
            {
              field: 'paperworkType',
              headerName: 'Tipologia pratica',
              width: 200,
              flex: 1,
            },
            {
              field: 'statusId',
              headerName: 'Stato',
              width: 300,
              renderCell: (params) => <PaperworkStatusChip status={params.value} />,
            },
            {
              ...stringDateColumn,
              field: 'expirationDate',
              headerName: 'Data scadenza',
              width: 50,
            },
            {
              field: 'link',
              headerName: '',
              width: 11,
              renderCell: () => (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ChevronRightIcon />
                </Box>
              ),
              sortable: false,
            },
          ]}
          disableDensitySelector
          disableColumnSelector
          disableColumnPinning
          hideFooter
          onRowClick={(row) => navigate(`/paperworks/${row.id}`)}
          sx={{
            border: 0,
            [`.${gridClasses.row}`]: {
              cursor: 'pointer',
            },
          }}
        />
      </Paper>
    </DetailSection>
  );
}

export const PaperworkLinkedTable = React.memo(PaperworkLinkedTableComponent);
