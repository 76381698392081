import React from 'react';
import { PaperworkRoute } from '../../entities/Paperwork';
import { Review } from '../../entities/Review';
import { SerniListUpdateReview } from '../../entities/SerniListUpdate';
import { useSerniListUpdate } from '../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useUpdateSerniListUpdateReviewMutation } from '../../services/serniListUpdate';
import { ReviewPopover, ReviewPopoverProps } from './ReviewPopover';

interface SerniListUpdateReviewPopoverProps {
  children: ReviewPopoverProps['children'];
  serniListUpdateReview?: Pick<SerniListUpdateReview, 'attachmentId' | 'equipmentId'>;
  review?: SerniListUpdateReview;
  routeOverride?: PaperworkRoute;
}

function SerniListUpdateReviewPopoverComponent(props: SerniListUpdateReviewPopoverProps) {
  const { serniListUpdateReview, review, children } = props;
  const { serniListUpdate } = useSerniListUpdate();
  const [updateSerniListUpdateReview, updateSerniListUpdateReviewRequest] = useUpdateSerniListUpdateReviewMutation();

  const onSubmit = async (review: Review) => {
    await updateSerniListUpdateReview({
      serniListId: serniListUpdate!.id,
      review,
      ...serniListUpdateReview,
    });
  };

  return (
    <ReviewPopover
      review={review?.review}
      onSubmitReview={onSubmit}
      isLoading={updateSerniListUpdateReviewRequest.isLoading}
    >
      {children}
    </ReviewPopover>
  );
}

export const SerniListUpdateReviewPopover = React.memo(SerniListUpdateReviewPopoverComponent);
