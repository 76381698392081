import { createListenerMiddleware, ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import debounce from 'lodash.debounce';
import { SerniListUpdate } from '../../entities/SerniListUpdate';
import { serniListUpdateApi } from '../../services/serniListUpdate';
import { store } from '../store';

export const serniListUpdateCompletionMiddleware = createListenerMiddleware();

async function checkCompletion(
  serniListUpdateId: SerniListUpdate['id'],
  state: ReturnType<typeof store.getState>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>
) {
  const { data: serniListUpdate } = await serniListUpdateApi.endpoints.readSerniListUpdate.select({
    serniListUpdateId,
  })(state);

  const { data: reviews } = await serniListUpdateApi.endpoints.readSerniListUpdateReviews.select({
    serniListUpdateId,
  })(state);

  let dataEntryCompleted = true;

  if (serniListUpdate && reviews) {
    // ##### Materials #######################################################################################################

    if (!serniListUpdate.equipmentList || serniListUpdate.equipmentList?.length === 0) {
      dataEntryCompleted = false;
    }
    // ##### Materials #######################################################################################################

    // ##### Reviews #######################################################################################################

    const equipments = serniListUpdate.equipmentList ?? [];
    const attachments = serniListUpdate.attachmentList ?? [];

    let equipmentsReviewCompleted = false;
    let attachmentsReviewCompleted = false;

    if (reviews) {
      // Check every material has a review
      equipmentsReviewCompleted = equipments.every((equipment) =>
        reviews.find((review) => review.equipmentId === equipment.equipment)
      );

      // Check every attachment has a review
      attachmentsReviewCompleted = attachments.every((attachment) =>
        reviews.find((review) => review.attachmentId === attachment.id)
      );
    }

    // ##### Reviews #######################################################################################################

    listenerApi.dispatch(
      serniListUpdateApi.endpoints.patchSerniListUpdateCompletedPages.initiate({
        id: serniListUpdateId,
        completedPages: {
          dataEntry: {
            dataEntryCompleted: dataEntryCompleted,
            reviewCompleted: attachmentsReviewCompleted && equipmentsReviewCompleted,
          },
        },
      })
    );
  }
}

// Middlewares
const debouncedCheckCompletion = debounce(checkCompletion, 1000, { leading: false, trailing: true });

/**
 * Update the completion & review status of the whole Serni List Update each time:
 * - The SLU has been read
 * - The SLU reviews have been read
 */

serniListUpdateCompletionMiddleware.startListening({
  matcher: serniListUpdateApi.endpoints.readSerniListUpdate.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const serniListUpdateId = action.meta.arg.originalArgs.serniListUpdateId;

    debouncedCheckCompletion(serniListUpdateId, state, listenerApi);
  },
});

serniListUpdateCompletionMiddleware.startListening({
  matcher: serniListUpdateApi.endpoints.readSerniListUpdateReviews.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const serniListUpdateId = action.meta.arg.originalArgs.serniListUpdateId;

    debouncedCheckCompletion(serniListUpdateId, state, listenerApi);
  },
});
