import React, { useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AlertIcon, InformationOutlineIcon } from '../../../../../components/Icons';
import { CancelButton } from '../../../../../components/NavigationPanel/CancelButton';
import { ForwardButton, SaveButton } from '../../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkDebugMenu } from '../../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { usePaperworkReviews } from '../../../../../components/review/usePaperworkReviews';
import { DraftOperationEnum } from '../../../../../entities/Drafts';
import { Material } from '../../../../../entities/Material';
import { useMaterials } from '../../../../../hooks/useMaterials';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../../../hooks/useNotifications';
import { usePaperwork } from '../../../../../hooks/usePaperwork/usePaperwork';
import { MaterialsStepLayout, MaterialsStepProps } from '../MaterialsStepLayout';
import { EditMaterialDialog } from './EditMaterialDialog';
import { ImportMaterialsDialog } from './ImportMaterialsDialog/ImportMaterialsDialog';
import { SelectPartNumbersDatagrid } from './SelectPartNumbersDatagrid';

function SelectPartNumbersComponent(
  props: MaterialsStepProps & {
    withoutEquipment?: boolean;
    withoutPn?: boolean;
  }
) {
  const { leftActions, primaryActions, onForward, withoutEquipment, withoutPn } = props;
  const { pushNotification, pushPredefinedNotification } = useNotifications();
  const { paperwork } = usePaperwork();

  const [selectedMaterial, setSelectedMaterial] = useState<Material | undefined>();
  const [editMaterialOpen, setEditMaterialOpen] = useState(false);

  const { showEditReviews } = usePaperworkReviews();

  const { materials, createMaterial, deleteMaterial, saveMaterials, operations, operationsCount, discardOperations } =
    useMaterials();

  const handleCancel = useCallback(async () => {
    await discardOperations();
    pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_DISCARD_SUCCESS);
  }, [discardOperations, pushPredefinedNotification]);

  async function handleDelete(materialId: Material['id']) {
    deleteMaterial(materialId);
    pushNotification('Materiale rimosso dalla pratica');
  }

  async function handlePnSelect(materialId: Material['id']) {
    setEditMaterialOpen(true);
    setSelectedMaterial(materials[materialId]);
  }

  const incompleteRows = useMemo(() => {
    const incompleteRows = new Set<string>();

    for (const [draftId, material] of Object.entries(materials)) {
      if (!material.program || !material.description || !material.eccn) {
        incompleteRows.add(draftId);
      }
      if (!withoutPn && !material.hts) {
        incompleteRows.add(draftId);
      }
      if (!withoutEquipment && !material.serniCode) {
        incompleteRows.add(draftId);
      }
    }

    return incompleteRows;
  }, [materials, withoutEquipment, withoutPn]);

  const rows = useMemo(() => {
    return Object.values(materials).filter((material) =>
      operations[material.id] === DraftOperationEnum.Values.Delete ? false : true
    );
  }, [materials, operations]);

  const totalOperationsCount = operationsCount;

  const primaryActionsWithForwardButton = (
    <>
      {primaryActions}
      <ForwardButton onClick={onForward} disabled={Object.values(materials).filter((m) => !!m.id).length < 1} />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActionsWithForwardButton}
          secondaryActions={[
            <CancelButton
              key="cancel"
              onConfirm={handleCancel}
              disabled={totalOperationsCount <= 0 || incompleteRows.size > 0}
            />,
            <SaveButton
              key="save"
              onClick={() => saveMaterials()}
              disabled={totalOperationsCount <= 0 || incompleteRows.size > 0}
            />,
          ]}
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={totalOperationsCount}
        />
      }
    >
      <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" marginBottom={1}>
        <Stack direction="column" gap={1}>
          {incompleteRows.size > 0 ? (
            <Alert icon={<AlertIcon fontSize="inherit" />} severity="warning">
              {'Integrare manualmente i dati dei P/N non trovati (evidenziati in giallo)'}
            </Alert>
          ) : showEditReviews ? (
            <Alert icon={<InformationOutlineIcon fontSize="inherit" />} severity="info">
              {'Proseguire allo step successivo per poter revisionare il materiale'}
            </Alert>
          ) : withoutPn ? (
            <Alert icon={<InformationOutlineIcon fontSize="inherit" />} severity="info">
              {'Inserire tutti gli equipaggiamenti relativi ai programmi oggetto della richiesta'}
            </Alert>
          ) : null}
          {paperwork && paperwork.paperworkTemplate.saleType?.name === 'Supply Chain' ? (
            <Alert icon={<InformationOutlineIcon fontSize="inherit" />} severity="info">
              {'Inserire tutti i P/N militari gestiti dal fornitore'}
            </Alert>
          ) : null}
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: 'auto' }}>
          {selectedMaterial && (
            <EditMaterialDialog
              open={editMaterialOpen}
              onClose={() => setEditMaterialOpen(false)}
              material={selectedMaterial}
              withoutEquipment={withoutEquipment}
              withoutPn={withoutPn}
            />
          )}
          {withoutPn ? (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedMaterial({
                  id: '',
                  imported: false,
                  description: '',
                });
                setEditMaterialOpen(true);
              }}
            >
              {'Aggiungi materiale'}
            </Button>
          ) : (
            <ImportMaterialsDialog
              onImportMaterials={(importedMaterials) => {
                for (const material of importedMaterials) {
                  createMaterial(material);
                }
              }}
              withoutEquipment={withoutEquipment}
            />
          )}
        </Stack>
      </Stack>
      <DataGridWrapper>
        <SelectPartNumbersDatagrid
          materials={rows}
          onDelete={handleDelete}
          onSelectMaterial={handlePnSelect}
          incompleteRows={incompleteRows}
          withoutEquipment={withoutEquipment}
          withoutPn={withoutPn}
        />
      </DataGridWrapper>
    </MaterialsStepLayout>
  );
}

export const SelectPartNumbers = React.memo(SelectPartNumbersComponent);
