import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { DropDownMenu } from '../../../components/DropDownMenu';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { useCreateClosurePaperworkMutation, useCreateExtensionPaperworkMutation } from '../../../services/paperwork';

function PaperworkManageMenuComponent() {
  const { paperwork } = usePaperwork();
  const navigate = useNavigate();
  const [extend] = useCreateExtensionPaperworkMutation();
  const [close] = useCreateClosurePaperworkMutation();

  if (!paperwork) return <></>;

  return (
    <>
      <DropDownMenu
        direction="up"
        label="Gestisci Pratica"
        slotProps={{
          button: {
            color: 'accent',
          },
        }}
      >
        {(onClose) => (
          <>
            <MenuItem
              onClick={async () => {
                onClose();
                const res = await extend({
                  paperworkId: paperwork!.id,
                }).unwrap();
                navigate(`/paperworks/${res.id}`);
              }}
            >
              {'Proroga'}
            </MenuItem>
            <MenuItem
              onClick={async () => {
                onClose();
                const res = await close({
                  paperworkId: paperwork!.id,
                }).unwrap();
                navigate(`/paperworks/${res.id}`);
              }}
            >
              {'Chiusura'}
            </MenuItem>
          </>
        )}
      </DropDownMenu>
    </>
  );
}

export const PaperworkManageMenu = React.memo(PaperworkManageMenuComponent);
