import React, { useMemo } from 'react';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { materialDescriptionColumn } from '../../../../components/DataGrid/materialDescriptionColumn';
import { useProgramColumn } from '../../../../components/DataGrid/programColumn';
import { DeleteIcon, EditIcon } from '../../../../components/Icons';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { ReviewGridActionsCellItem } from '../../../../components/review/ReviewGridActionsCellItem';
import { SerniListUpdateReviewGuard } from '../../../../components/review/ReviewGuard';
import { SerniListUpdateReviewPopover } from '../../../../components/review/SerniListUpdateReviewPopover';
import { useSerniListUpdateReviews } from '../../../../components/review/useSerniListUpdateReviews';
import { EquipmentDraft } from '../../../../entities/Equipment';
import { PaperworkReview } from '../../../../entities/Paperwork';
import { SerniListUpdateReview } from '../../../../entities/SerniListUpdate';
import { StakeholderRow } from '../../../Paperwork/DataEntry/Stakeholders/StakeholderRow';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

interface EquipmentDraftRow extends EquipmentDraft {
  review?: SerniListUpdateReview;
}

interface EquipmentsStepDatagridProps {
  equipments: EquipmentDraftRow[];
  onSelectEquipment: (material: EquipmentDraft) => void;
  onDelete: (id: EquipmentDraft['equipment']) => void;
}

function EquipmentsStepDatagridComponent(props: EquipmentsStepDatagridProps) {
  const { equipments, onSelectEquipment, onDelete } = props;

  const programColumn = useProgramColumn();
  const { reviews, showReviews } = useSerniListUpdateReviews();

  const equipmentRows = useMemo(() => {
    return equipments.map((equipment) => ({
      ...equipment,
      review: reviews.find((review) => review.equipmentId === equipment.equipment),
    }));
  }, [equipments, reviews]);

  const dataGridColumns = useMemo(() => {
    const columns: Array<GridColDef | GridActionsColDef> = [
      { ...materialDescriptionColumn, field: 'equipment', headerName: 'Nome' },
      { ...materialDescriptionColumn, field: 'equipmentDescription', headerName: 'Descrizione', flex: 2 },
      { ...programColumn, field: 'equipmentProgram', valueGetter: (value) => [value], headerName: 'Programma' },
    ];

    if (showReviews) {
      columns.push({
        field: 'review',
        type: 'boolean',
        headerName: 'Stato revisione',
        width: 130,
        renderCell: ({ row }: GridRenderCellParams<StakeholderRow, PaperworkReview>) =>
          row.review ? <ReviewChip review={row.review} /> : null,
      });
    }

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 120,
      getActions: ({ row }: GridRowParams<EquipmentDraftRow>) => [
        <SerniListUpdateReviewGuard key="review" editReview>
          <SerniListUpdateReviewPopover
            serniListUpdateReview={{
              equipmentId: row.equipment,
            }}
            review={row.review}
          >
            {(onCLick) => <ReviewGridActionsCellItem onClick={onCLick} highlight={!row.review} />}
          </SerniListUpdateReviewPopover>
        </SerniListUpdateReviewGuard>,
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          onClick={() => onSelectEquipment(row)}
          label="Modifica equipaggiamento"
          title="Modifica equipaggiamento"
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          onClick={() => onDelete(row.equipment)}
          label="Elimina equipaggiamento"
          title="Elimina equipaggiamento"
        />,
      ],
    });

    return columns;
  }, [onDelete, onSelectEquipment, programColumn, showReviews]);

  return (
    <DataGridPremium
      density="compact"
      rows={equipmentRows}
      columns={dataGridColumns}
      pinnedColumns={pinnedColumns}
      disableRowGrouping
      disableAggregation
      getRowId={(equipment) => equipment.equipment}
      sx={{ border: 0 }}
    />
  );
}

export const EquipmentsStepDatagrid = React.memo(EquipmentsStepDatagridComponent);
