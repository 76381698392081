import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useReadSerniListUpdateQuery } from '../../services/serniListUpdate';

export function useSerniListUpdate(options?: { skip?: boolean } | undefined) {
  const params = useParams<{ serniListUpdateId: string }>();

  const readSerniListUpdateQuery = useReadSerniListUpdateQuery(
    { serniListUpdateId: Number(params.serniListUpdateId) },
    { skip: options?.skip || params.serniListUpdateId === undefined }
  );

  return useMemo(
    () => ({
      serniListUpdate: readSerniListUpdateQuery.data,
      serniListUpdateRequest: readSerniListUpdateQuery,
    }),
    [readSerniListUpdateQuery]
  );
}
