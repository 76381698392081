import React from 'react';
import TextField from '@mui/material/TextField';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function ExtensionReasonInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  return (
    <TextField
      fullWidth
      multiline
      label={field.label}
      value={fieldValue?.value ?? ''}
      onChange={(event) => {
        return updateValue(event.target.value ?? undefined);
      }}
    />
  );
}

export const ExtensionReasonInput = React.memo(ExtensionReasonInputComponent);
