import React, { forwardRef, useMemo } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { MovementType } from '../../../entities/MovementType';
import { useReadMovementTypeListQuery } from '../../../services/movementType';

export type MovementTypeAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<MovementType['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'> & {
    filteredIdValues?: number[];
  };

function MovementTypeAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: MovementTypeAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, filteredIdValues, disabled, ...autocompleteProps } =
    props;
  const { data, isFetching, error: loadingError } = useReadMovementTypeListQuery();

  const map = useMemo(() => {
    const m: Record<string, MovementType> = {};
    if (data) data.forEach((s) => (m[s.id] = s));
    return m;
  }, [data]);

  return (
    <Autocomplete
      value={value}
      options={data?.map(({ id }) => id).filter((d) => filteredIdValues?.includes(d)) ?? []}
      getOptionLabel={(id) => {
        return map[id as string]?.name || 'unknown';
      }}
      disabled={disabled || (data && data.map(({ id }) => id).filter((d) => filteredIdValues?.includes(d)).length < 1)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          inputRef={ref}
          sx={sx}
        />
      )}
      loading={isFetching}
      {...autocompleteProps}
    />
  );
}

export const MovementTypeAutocomplete = forwardRef(
  MovementTypeAutocompleteComponent
) as typeof MovementTypeAutocompleteComponent;
