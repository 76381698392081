import React from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { SerniListUpdateReviewGuard } from '../../../../components/review/ReviewGuard';
import { SerniListUpdateReviewPopover } from '../../../../components/review/SerniListUpdateReviewPopover';
import { useSerniListUpdateReviews } from '../../../../components/review/useSerniListUpdateReviews';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { useSerniListUpdate } from '../../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { formatDateTime } from '../../../../utils/dates';
import { DownloadButton } from '../../../Paperwork/DataEntry/Documents/DownloadButton';
import { AttachmentDeleteDialog } from './AttachmentsDeleteDialog';

interface AttachmentCardProps extends CardProps {
  attachment: PaperworkAttachment['attachment'];
  onDeleteAttachment: (id: PaperworkAttachment['attachment']['id']) => void;
}

function AttachmentCardComponent(props: AttachmentCardProps) {
  const { attachment, onDeleteAttachment, ...cardProps } = props;
  const { serniListUpdate } = useSerniListUpdate();
  const { reviews } = useSerniListUpdateReviews();

  const review = reviews.find(
    (review) => review.serniListId === serniListUpdate?.id && attachment.id === review.attachmentId
  );

  return (
    <Card {...cardProps}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
            {attachment.name}
          </Typography>
          <DownloadButton attachment={{ attachment }} />
          <AttachmentDeleteDialog attachment={attachment} onDeleteAttachment={onDeleteAttachment} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="body2" flex={1}>
            {`Documento caricato il ${formatDateTime(new Date(attachment.uploadDate))}`}
          </Typography>
        </Stack>
      </CardContent>
      <SerniListUpdateReviewGuard>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
          <ReviewChip review={review} />
          <SerniListUpdateReviewGuard editReview>
            <SerniListUpdateReviewPopover
              serniListUpdateReview={{
                attachmentId: attachment.id,
              }}
              review={review}
            >
              {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
            </SerniListUpdateReviewPopover>
          </SerniListUpdateReviewGuard>
        </Stack>
      </SerniListUpdateReviewGuard>
    </Card>
  );
}

export const AttachmentCard = React.memo(AttachmentCardComponent);
