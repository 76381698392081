import React, { useId, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CheckConfirmDialog } from '../../../../components/CheckConfirmDialog';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import {
  PAPERWORK_FIELD_DATATYPE,
  addDraftIdToPaperworkFieldValue,
  getPaperworkFieldValueDraftId,
} from '../../../../entities/Paperwork';
import { Stakeholder } from '../../../../entities/Stakeholder';
import { BillToSubsections, EndUsersSubsections } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useStakeholderAlerts } from '../../../../hooks/usePaperwork/useStakeholderAlerts';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { paperworksApi } from '../../../../services/paperwork';
import { stakeholderListenerMiddleware } from '../../../../store/listeners/stakeholderListenerMiddleware';
import { StakeholdersSection } from './StakeholdersSection';
import { StakeholdersStepProps } from './StakeholdersStepLayout';

function StakeholdersSkipInputSectionSectionComponent(
  props: StakeholdersStepProps & {
    role: NonNullable<Stakeholder['role']>;
    optionFieldDatatype: PAPERWORK_FIELD_DATATYPE;
  }
) {
  const { role, optionFieldDatatype, stepper } = props;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const radioId = useId();

  const { stakeholders, deleteStakeholder, saveStakeholders } = useStakeholders();
  const { paperwork } = usePaperwork();
  const { reviews } = usePaperworkReviews();
  const { route } = usePaperworkNavigation();

  const reviewRouteOverride = useMemo(
    () => route?.subsections?.[EndUsersSubsections.SkipStakeholdersInputPanel],
    [route?.subsections]
  );

  const review = useMemo(
    () =>
      reviews.find(
        (review) => review.paperworkId === paperwork?.id && reviewRouteOverride?.context?.id === review.contextId
      ),
    [paperwork?.id, reviews, reviewRouteOverride?.context?.id]
  );

  const { contextFields, fieldValues, originalFieldValues, updateValue, saveValues } = usePaperworkFields({
    subsection: BillToSubsections.SkipStakeholdersInputPanel,
  });

  const [contextField, optionFieldValue, optionFieldOriginalValue] = useMemo(() => {
    const draftId = getPaperworkFieldValueDraftId({ fieldDatatype: optionFieldDatatype, progIndex: 0 });
    return [
      contextFields.find((field) => field.datatype === optionFieldDatatype),
      fieldValues[draftId],
      originalFieldValues[draftId],
    ];
  }, [contextFields, fieldValues, optionFieldDatatype, originalFieldValues]);

  const [valueChanged, setValueChanged] = useState(false);

  function handleValueChange(e: React.ChangeEvent, value: string) {
    setValueChanged(true);
    updateValue(
      addDraftIdToPaperworkFieldValue({
        ...(optionFieldValue ?? {
          fieldDatatype: optionFieldDatatype,
          progIndex: 0,
        }),
        value: value,
      })
    );
  }

  const sectionStakeholders = useMemo(
    () => Object.values(stakeholders).filter((stakeholder) => stakeholder.role === role),
    [role, stakeholders]
  );

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (
      (optionFieldValue.value === 'microtecnica' || optionFieldValue.value === 'ship-to') &&
      sectionStakeholders.length
    ) {
      setConfirmDialogOpen(true);
    } else {
      setValueChanged(false);
      saveValues();
    }
  }

  const stakeholderAlerts = useStakeholderAlerts(role);

  return (
    <Stack direction="column" gap={2} minHeight={0} flex={1} maxWidth="100vw">
      <StakeholdersSection
        hideDatagrid={
          !optionFieldOriginalValue ||
          optionFieldOriginalValue?.value === 'microtecnica' ||
          optionFieldOriginalValue?.value === 'ship-to'
        }
        {...props}
        forceEnableForwardButton={optionFieldValue?.value === 'microtecnica' || optionFieldValue?.value === 'ship-to'}
        stepper={stepper}
        disableSaveButton={valueChanged}
        stakeholderAlerts={[]}
      >
        <Stack direction="column" gap={1}>
          {stakeholderAlerts.map((stakeholderAlert) => (
            <Alert severity="warning" key={stakeholderAlert}>
              {stakeholderAlert}
            </Alert>
          ))}
        </Stack>
        <PaperworkReviewGuard>
          <Stack direction="row">
            <ReviewChip review={review} size="medium" />
          </Stack>
        </PaperworkReviewGuard>
        <Box sx={{ paddingX: 0 }}>
          <Card>
            <Stack component="form" onSubmit={handleSubmit} direction="row" gap={2} sx={{ padding: 2 }}>
              <FormControl
                sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: 2 }}
              >
                <FormLabel id={radioId}>
                  <Typography color="text.primary">{`${contextField?.label ?? 'contextField.label'}?`}</Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby={radioId}
                  name="coincident-microtecnica-radio-buttons-group"
                  value={`${optionFieldValue?.value ?? ''}`}
                  onChange={handleValueChange}
                  sx={{ gap: 2 }}
                >
                  <FormControlLabel
                    value="microtecnica"
                    control={<Radio />}
                    label="L'End User coincide con Microtecnica"
                  />
                  <FormControlLabel value="ship-to" control={<Radio />} label="L'End User coincide con lo Ship To" />
                  <FormControlLabel value="false" control={<Radio />} label="Nessuna delle precedenti" />
                </RadioGroup>
              </FormControl>
              <Box>
                <Button type="submit" variant="contained" disabled={optionFieldValue === optionFieldOriginalValue}>
                  {'Applica'}
                </Button>
              </Box>
              <PaperworkReviewGuard editReview>
                <PaperworkReviewPopover review={review} routeOverride={reviewRouteOverride}>
                  {(onClick) => <ReviewButton onClick={onClick} size="medium" highlight={!review} />}
                </PaperworkReviewPopover>
              </PaperworkReviewGuard>
            </Stack>
          </Card>
        </Box>
      </StakeholdersSection>
      <CheckConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          // Throw away all the stakeholders previously inserted by the user,
          // along with their related field values. Then save the state of the radio button,
          // tracked by optionFieldValue
          for (const [_, fieldValue] of Object.entries(fieldValues)) {
            if (
              fieldValue.stakeholderId &&
              sectionStakeholders.map((stakeholder) => stakeholder.id).includes(fieldValue.stakeholderId)
            ) {
              if (fieldValue.fieldDatatype !== optionFieldDatatype) {
                updateValue({ ...fieldValue, value: undefined });
              }
            }
          }
          for (const stakeholder of sectionStakeholders) {
            deleteStakeholder(stakeholder.id);
          }

          saveStakeholders();
          const unsubscribeListener = stakeholderListenerMiddleware.startListening({
            matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
            effect: async () => {
              setValueChanged(false);
              saveValues();
              unsubscribeListener();
            },
          });
        }}
        dialogTitleLabel="Rimozione parti coinvolte inserite"
        dialogDescriptionLabel="Confermando, le Parti Coinvolte attualmente presenti in questa sezione saranno rimosse."
        cancelButtonLabel="Annulla"
        confirmButtonLabel="Elimina"
        confirmTextLabel="Confermo di voler rimuovere le Parti Coinvolte inserite"
      />
    </Stack>
  );
}

export const StakeholdersSkipInputSectionSection = React.memo(StakeholdersSkipInputSectionSectionComponent);
