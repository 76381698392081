import React, { Fragment, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { Stakeholder } from '../../../../entities/Stakeholder';
import { useAttachmentAlerts } from '../../../../hooks/usePaperwork/useAttachmentAlerts';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useAttachments } from '../../../../hooks/usePaperworkAttachments';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { AddAttachmentDialog } from './AddAttachmentDialog';
import { AttachmentCard } from './AttachmentCard';
import { AttachmentsByStakeholder } from './AttachmentsByStakeholder';
import { AttachmentsSectionTabPanel } from './AttachmentsSectionTabPanel';

function a11yProps(index: number) {
  return {
    id: `attachment-tab-${index}`,
    'aria-controls': `attachment-tabpanel-${index}`,
  };
}

function AttachmentsSectionPageComponent(props: {
  filteredStakeholders?: Record<string, Stakeholder>;
  hasMilitaryBases: boolean;
  onlyMilitaryBases: boolean;
}) {
  const { hasMilitaryBases, onlyMilitaryBases } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState(searchParams.get('view') === 'by-stakeholder' ? 1 : 0);
  const stakeholderAlerts = useAttachmentAlerts();

  const { stakeholders: hookUseStakeholders } = useStakeholders();

  const stakeholders = useMemo(
    () =>
      !props.filteredStakeholders
        ? Object.keys(hookUseStakeholders).length > 0
          ? hookUseStakeholders
          : undefined
        : props.filteredStakeholders,
    [hookUseStakeholders, props.filteredStakeholders]
  );

  const { route } = usePaperworkNavigation();

  const { attachments, operations } = useAttachments();

  // Either return this section's subsections, or the section itself, if it has no subsection
  const subsections = useMemo(() => {
    if (!route) {
      return [];
    }

    if (route.subsections) {
      return Object.values(route.subsections).filter((subsection) => subsection.enabled === true);
    }

    return [route];
  }, [route]);

  const sectionContexts = useMemo(() => {
    const sectionContexts = [];

    if (route?.context) {
      sectionContexts.push(route?.context?.id);
    }

    if (route?.subsections) {
      for (const [_, subsection] of Object.entries(route.subsections)) {
        if (subsection.context) {
          sectionContexts.push(subsection.context.id);
        }
      }
    }

    return sectionContexts;
  }, [route]);

  const { attachmentsBySubsection, attachmentsByStakeholder } = useMemo(() => {
    const attachmentsBySubsection: Record<number, PaperworkAttachment[]> = {};
    const attachmentsByStakeholder: Record<Stakeholder['id'], Record<number, PaperworkAttachment[]>> = {};

    for (const [_, attachment] of Object.entries(attachments)) {
      if (sectionContexts.includes(attachment.savedInContextId)) {
        attachmentsBySubsection[attachment.savedInContextId] =
          attachmentsBySubsection[attachment.savedInContextId] ?? [];
        attachmentsBySubsection[attachment.savedInContextId].push(attachment);

        for (const stakeholderId of attachment?.stakeholderIdList ?? []) {
          attachmentsByStakeholder[stakeholderId] = attachmentsByStakeholder[stakeholderId] ?? {};
          attachmentsByStakeholder[stakeholderId][attachment.savedInContextId] =
            attachmentsByStakeholder[stakeholderId][attachment.savedInContextId] ?? [];
          attachmentsByStakeholder[stakeholderId][attachment.savedInContextId].push(attachment);
        }
      }
    }

    return { attachmentsBySubsection, attachmentsByStakeholder };
  }, [attachments, sectionContexts]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setSearchParams({ view: 'by-type' });
    } else {
      setSearchParams({ view: 'by-stakeholder' });
    }
  };

  if (!route?.context) {
    return <Alert severity="error">{`Missing context for route "${route?.path}"`}</Alert>;
  }

  return (
    <Stack direction="column" width="100%" height="100%" gap={2}>
      <Stack direction="column" gap={1}>
        {onlyMilitaryBases ? (
          <Alert severity="warning">
            {'Le parti coinvolte sono tutte basi militari, non è necessario allegare documenti'}
          </Alert>
        ) : hasMilitaryBases ? (
          <Alert severity="warning">
            {
              'Le parti coinvolte precedentemente contrassegnate come "base militare" non sono mostrate in quanto non è necessario allegare documenti per esse'
            }
          </Alert>
        ) : null}
        {stakeholderAlerts.map((alert) => (
          <Alert key={alert} severity="warning">
            {alert}
          </Alert>
        ))}
      </Stack>
      <Stack component={Paper} direction="column" width="100%" height="100%">
        <Stack direction="row" alignItems="flex-end" gap={2}>
          <Box flex={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
              <Tab label="Per tipologia di documento" {...a11yProps(0)} />
              <Tab label="Per parte coinvolta" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Stack>
        <AttachmentsSectionTabPanel value={value} index={0}>
          {subsections.map((subsection, subsectionIndex) => {
            let attachmentsByAttachmentId: PaperworkAttachment[] = [];

            if (subsection.context?.id) {
              attachmentsByAttachmentId = attachmentsBySubsection[subsection.context.id] ?? [];
            }

            const cards: React.ReactNode[] = [];

            for (const [attachmentId, attachment] of Object.entries(attachmentsByAttachmentId)) {
              if (route && !operations[attachment.attachment.id]) {
                // Only show the card if is saved on DB (and then has an ID)
                if (attachment.attachment.id) {
                  cards.push(
                    <Grid item xs={12} xl={6} key={attachmentId}>
                      <AttachmentCard paperworkAttachment={attachment} />
                    </Grid>
                  );
                }
              }
            }

            return (
              <Fragment key={subsection.path}>
                <Stack direction="column" gap={1.5} padding={2}>
                  <Stack direction="row">
                    <Typography variant="h5" flex={1}>
                      {subsection.title}
                    </Typography>
                    <AddAttachmentDialog
                      subsection={subsection}
                      stakeholders={stakeholders}
                      disabled={onlyMilitaryBases}
                    />
                  </Stack>
                  {cards.length ? (
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      {cards}
                    </Grid>
                  ) : (
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
                      <Typography color="text.disabled" textAlign="center">
                        {'Nessun documento aggiunto'}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                {subsectionIndex < subsections.length - 1 ? <Divider /> : <Box height="10vh" />}
              </Fragment>
            );
          })}
        </AttachmentsSectionTabPanel>
        {stakeholders && (
          <AttachmentsSectionTabPanel value={value} index={1} sx={{ padding: 2 }}>
            <AttachmentsByStakeholder
              key={value}
              attachmentsByStakeholder={attachmentsByStakeholder}
              stakeholders={props.filteredStakeholders ?? stakeholders}
            />
          </AttachmentsSectionTabPanel>
        )}
      </Stack>
    </Stack>
  );
}

export const AttachmentsSectionPage = React.memo(AttachmentsSectionPageComponent);
