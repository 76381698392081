import React from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function ExtensionDateInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  return (
    <DatePicker
      label={field.label}
      value={fieldValue?.value ? new Date(fieldValue.value) : null}
      onChange={(value) => {
        return updateValue(value ? value.toISOString() : undefined);
      }}
      slotProps={{
        textField: {
          fullWidth: true,
        },
      }}
    />
  );
}

export const ExtensionDateInput = React.memo(ExtensionDateInputComponent);
