import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { PAPERWORK_FIELD_DATATYPE, PaperworkField } from '../../entities/Paperwork';
import { AttachmentsDigitallySignedRadioButton } from './components/AttachmentsDigitallySignedRadioButton';
import { AttachmentsOriginalAvailableRadioButton } from './components/AttachmentsOriginaAvailableRadioButton';
import { ClosureCurrencyInput } from './components/ClosureCurrencyInput';
import { ClosureDateInput } from './components/ClosureDateInput';
import { ClosureValueInput } from './components/ClosureValueInput';
import { ContractualTermsLoiDateTextField } from './components/ContractualTermsLoiDateTextField';
import { ContractualTermsLoiReferenceTextField } from './components/ContractualTermsLoiReferenceTextField';
import { ContractualTermsPaymentTermsAutocomplete } from './components/ContractualTermsPaymentTermsAutocomplete';
import { ContractualTermsYieldAutocomplete } from './components/ContractualTermsYieldAutocomplete';
import { ExtensionDateInput } from './components/ExtensionDateInput';
import { ExtensionReasonInput } from './components/ExtensionReasonInput';
import { MaterialMultipleNumericValueInput } from './components/MaterialMultipleNumericValueInput';
import { MaterialPriceAdjustmentInput } from './components/MaterialPriceAdjustmentInput';
import { TotalQuantityInput } from './components/TotalQuantityInput';

const ErrorField = (props: { field: PaperworkField }) => (
  <Box
    sx={{
      padding: 1,
      typography: 'caption',
      backgroundColor: (theme) => alpha(theme.palette.error.light, 0.25),
      borderRadius: 1,
    }}
  >
    <pre>{JSON.stringify({ props }, null, 2)}</pre>
    {`Missing datatype component for datatype "${props.field.datatype}"`}
  </Box>
);

export function getFieldComponent(field: PaperworkField): React.ReactNode {
  const datatype = field.datatype as PAPERWORK_FIELD_DATATYPE;
  switch (datatype) {
    case PAPERWORK_FIELD_DATATYPE.CONTRACTUAL_TERMS_YIELD:
      return <ContractualTermsYieldAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.CONTRACTUAL_TERMS_INBOUND_YIELD:
      return <ContractualTermsYieldAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.CONTRACTUAL_TERMS_OUTBOUND_YIELD:
      return <ContractualTermsYieldAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.CONTRACTUAL_TERMS_PAYMENT_TERMS:
      return <ContractualTermsPaymentTermsAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED:
      return <AttachmentsDigitallySignedRadioButton field={field} />;
    case PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE:
      return <AttachmentsOriginalAvailableRadioButton field={field} />;
    case PAPERWORK_FIELD_DATATYPE.MATERIAL_PRICE_ADJUSTMENTS:
      return <MaterialPriceAdjustmentInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.MATERIAL_MILESTONES:
      return <MaterialMultipleNumericValueInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.MATERIAL_ANTICIPATED_PAYMENTS:
      return <MaterialMultipleNumericValueInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.MATERIAL_NOT_RECURRING_COSTS_PAYMENTS_TRANCHES:
      return <MaterialMultipleNumericValueInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.LOI_DATE:
      return <ContractualTermsLoiDateTextField field={field} />;
    case PAPERWORK_FIELD_DATATYPE.LOI_REFERENCE:
      return <ContractualTermsLoiReferenceTextField field={field} />;
    case PAPERWORK_FIELD_DATATYPE.PAYMENT_TERMS_VENDOR_PAYMENT:
      return <ContractualTermsPaymentTermsAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.PAYMENT_TERMS_CUSTOMER_PAYMENT:
      return <ContractualTermsPaymentTermsAutocomplete field={field} />;
    case PAPERWORK_FIELD_DATATYPE.TOTAL_QUANTITY:
      return <TotalQuantityInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.EXTENSION_DATE:
      return <ExtensionDateInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.EXTENSION_REASON:
      return <ExtensionReasonInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.CLOSURE_DATE:
      return <ClosureDateInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.CLOSURE_VALUE:
      return <ClosureValueInput field={field} />;
    case PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY:
      return <ClosureCurrencyInput field={field} />;
  }

  return <ErrorField field={field} />;
}
