import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { ForwardButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { EquipmentDraft } from '../../../../entities/Equipment';
import { DataEntryStepProps, DataEntryStepLayout } from '../DataEntryStepLayout';
import { EditEquipmentDialog } from './EditEquipmentDialog';
import { EquipmentsStepDatagrid } from './EquipmentsStepDatagrid';

interface EquipmentsStepProps extends DataEntryStepProps {
  equipments: EquipmentDraft[];
  onEquipmentsChange: (equipments: EquipmentDraft[]) => void;
  saveButton: React.ReactNode;
  unsavedChanges: boolean;
}

function EquipmentsStepComponent(props: EquipmentsStepProps) {
  const { equipments, onEquipmentsChange, leftActions, primaryActions, onForward, saveButton } = props;

  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDraft | undefined>();
  const [addEquipmentDialogOpen, setAddEquipmentDialogOpen] = useState(false);
  const [editEquipmentDialogOpen, setEditEquipmentDialogOpen] = useState(false);

  const primaryActionsWithForwardButton = (
    <>
      {primaryActions}
      <ForwardButton onClick={onForward} disabled={props.unsavedChanges} />
    </>
  );

  return (
    <DataEntryStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActionsWithForwardButton}
          secondaryActions={[saveButton]}
          leftActions={leftActions}
        />
      }
    >
      <Stack direction="row" justifyContent="flex-end" alignItems="center" flexWrap="wrap" marginBottom={1}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: 'auto' }}>
          {selectedEquipment && (
            <EditEquipmentDialog
              open={editEquipmentDialogOpen}
              onClose={() => setEditEquipmentDialogOpen(false)}
              equipment={selectedEquipment}
              equipments={equipments}
              onSubmit={(updatedEquipment) => {
                const newEquipments = equipments.filter(
                  (equipment) => equipment.equipment !== selectedEquipment.equipment
                );
                onEquipmentsChange([...newEquipments, updatedEquipment]);
              }}
            />
          )}
        </Stack>
        <Button variant="contained" onClick={() => setAddEquipmentDialogOpen(true)}>
          {'Aggiungi equipaggiamento'}
        </Button>
        <EditEquipmentDialog
          open={addEquipmentDialogOpen}
          equipments={equipments}
          onClose={() => setAddEquipmentDialogOpen(false)}
          onSubmit={(e) => onEquipmentsChange([...equipments, e])}
        />
      </Stack>
      <DataGridWrapper>
        <EquipmentsStepDatagrid
          equipments={equipments}
          onDelete={(deltedEquipment) => {
            onEquipmentsChange(equipments.filter((equipment) => equipment.equipment !== deltedEquipment));
          }}
          onSelectEquipment={(equipment) => {
            setSelectedEquipment(equipment);
            setEditEquipmentDialogOpen(true);
          }}
        />
      </DataGridWrapper>
    </DataEntryStepLayout>
  );
}

export const EquipmentsStep = React.memo(EquipmentsStepComponent);
