import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ForwardButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { DataEntryStepProps, DataEntryStepLayout } from '../DataEntryStepLayout';
import { AddAttachmentDialog } from './AddAttachmentDialog';
import { AttachmentCard } from './AttachmentCard';

interface EquipmentsStepProps extends DataEntryStepProps {
  attachments: PaperworkAttachment['attachment'][];
  onAttachmentsChange: (attachments: PaperworkAttachment['attachment'][]) => void;
  saveButton: React.ReactNode;
  unsavedChanges: boolean;
}

function AttachmentsStepComponent(props: EquipmentsStepProps) {
  const { attachments, onAttachmentsChange, leftActions, primaryActions, onForward, saveButton } = props;

  const primaryActionsWithForwardButton = (
    <>
      {primaryActions}
      <ForwardButton onClick={onForward} disabled={props.unsavedChanges} />
    </>
  );

  const cards: React.ReactNode[] = [];

  for (const attachment of attachments) {
    cards.push(
      <Grid item xs={12} xl={6} key={attachment.id}>
        <AttachmentCard
          attachment={attachment}
          onDeleteAttachment={(id) => {
            onAttachmentsChange(attachments.filter((attachment) => attachment.id !== id));
          }}
        />
      </Grid>
    );
  }

  return (
    <DataEntryStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActionsWithForwardButton}
          secondaryActions={[saveButton]}
          leftActions={leftActions}
        />
      }
    >
      <Stack direction="row" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
        <AddAttachmentDialog onAddAttachment={(attachment) => onAttachmentsChange([...attachments, attachment])} />
      </Stack>
      <Fragment>
        <Stack direction="column" gap={1.5} paddingX={2}>
          <Stack direction="row"></Stack>
          {cards.length ? (
            <Grid container rowSpacing={1} columnSpacing={1}>
              {cards}
            </Grid>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
              <Typography color="text.disabled" textAlign="center">
                {'Nessun documento aggiunto'}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Fragment>
    </DataEntryStepLayout>
  );
}

export const AttachmentsStep = React.memo(AttachmentsStepComponent);
