import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DropDownMenu } from '../../../components/DropDownMenu';
import { useSerniListUpdateReviews } from '../../../components/review/useSerniListUpdateReviews';
import { SERNI_LIST_UPDATE_STATUS } from '../../../entities/SerniListUpdate';
import { UserRole } from '../../../entities/User';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useReadSerniListUpdateStatusListQuery } from '../../../services/serniListUpdate';
import { SerniListUpdateDeleteDialog } from '../SerniListUpdateDeleteDialog';
import { ChangeStatusConfirmDialog } from './ChangeStatusConfirmDialog';

function SerniListUpdatePrimaryActionsComponent() {
  const { serniListUpdate } = useSerniListUpdate();
  const { hasRole, isAdmin, username } = useAuth();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data: serniListUpdateStatus } = useReadSerniListUpdateStatusListQuery();
  const { allDataReviewed, hasRejectedReviews } = useSerniListUpdateReviews();

  if (!serniListUpdate || !serniListUpdateStatus) {
    return null;
  }

  function statusMenuItem(label: string, handleClose: () => void, disabled = false) {
    // eslint-disable-next-line react/display-name
    return (onClick: () => void) => (
      <MenuItem
        onClick={() => {
          handleClose();
          onClick();
        }}
        disabled={disabled}
      >
        {label}
      </MenuItem>
    );
  }

  if (serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.DRAFT) {
    return (
      <>
        <Button onClick={() => setDeleteDialogOpen(true)} variant="contained" color="accent">
          {'Elimina richiesta in bozza'}
        </Button>
        <ChangeStatusConfirmDialog
          sourceStatus={serniListUpdate.status}
          targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.WAITING]}
        >
          {(onClick) => (
            <Button variant="contained" color="accent" onClick={onClick} /* disabled={!allDataEntryCompleted} */>
              {'Assegna pratica a GT'}
            </Button>
          )}
        </ChangeStatusConfirmDialog>
        <SerniListUpdateDeleteDialog
          open={deleteDialogOpen}
          serniListUpdateId={serniListUpdate.id}
          handleClose={() => {
            setDeleteDialogOpen(false);
          }}
          handleConfirm={() => {
            navigate('/serni-list-updates');
          }}
        />
      </>
    );
  }

  if (serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.WAITING) {
    if (username) {
      if (hasRole(UserRole.GT) || isAdmin) {
        return (
          <ChangeStatusConfirmDialog
            sourceStatus={serniListUpdate.status}
            targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.TAKEN_CHARGE]}
            assignee={username}
          >
            {(onClick) => (
              <Button variant="contained" color="accent" onClick={onClick}>
                {'Prendi in carico'}
              </Button>
            )}
          </ChangeStatusConfirmDialog>
        );
      }
    }
  }

  if (serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.TAKEN_CHARGE) {
    if (hasRole(UserRole.GT) || isAdmin) {
      return (
        <>
          <DropDownMenu
            direction="up"
            label="Gestisci stato pratica"
            slotProps={{
              button: {
                variant: 'contained',
                color: 'accent',
              },
              menu: {
                keepMounted: true,
              },
            }}
          >
            {(onClose) => (
              <>
                <ChangeStatusConfirmDialog
                  sourceStatus={serniListUpdate.status}
                  targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.APPROVED]}
                >
                  {statusMenuItem('Approva', onClose, hasRejectedReviews || !allDataReviewed)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={serniListUpdate.status}
                  targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.CONDITIONAL_APPROVED]}
                >
                  {statusMenuItem('Approva con riserva', onClose, hasRejectedReviews || !allDataReviewed)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={serniListUpdate.status}
                  targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.REJECTED]}
                >
                  {statusMenuItem('Rifiuta', onClose)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={serniListUpdate.status}
                  targetStatus={serniListUpdateStatus?.map[SERNI_LIST_UPDATE_STATUS.DRAFT]}
                >
                  {statusMenuItem('Tornata al mittente', onClose)}
                </ChangeStatusConfirmDialog>
              </>
            )}
          </DropDownMenu>
        </>
      );
    }
  }

  return null;
}

export const SerniListUpdatePrimaryActions = React.memo(SerniListUpdatePrimaryActionsComponent);
