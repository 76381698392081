import React from 'react';
import { PaperworkReview, PaperworkRoute } from '../../entities/Paperwork';
import { Review } from '../../entities/Review';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../hooks/usePaperwork/usePaperworkNavigation';
import { useUpdatePaperworkReviewMutation } from '../../services/paperworkReview';
import { ReviewPopover, ReviewPopoverProps } from './ReviewPopover';

export interface PaperworkReviewPopoverProps {
  children: ReviewPopoverProps['children'];
  paperworkReview?: Pick<PaperworkReview, 'attachmentId' | 'materialId' | 'stakeholderId'>;
  review?: PaperworkReview;
  routeOverride?: PaperworkRoute;
}

function PaperworkReviewPopoverComponent(props: PaperworkReviewPopoverProps) {
  const { paperworkReview, review, routeOverride, children } = props;
  const { paperwork } = usePaperwork();
  const { route } = usePaperworkNavigation();
  const [updatePaperworkReview, updatePaperworkReviewRequest] = useUpdatePaperworkReviewMutation();

  const onSubmit = async (review: Review) => {
    // Associate the review to the provided route, or guess it from the routing
    const reviewRoute = routeOverride ?? route;

    if (reviewRoute?.context) {
      await updatePaperworkReview({
        paperworkId: paperwork!.id,
        contextId: reviewRoute.context.id,
        review,
        ...paperworkReview,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Missing route context required for review');
    }
  };

  return (
    <ReviewPopover review={review?.review} onSubmitReview={onSubmit} isLoading={updatePaperworkReviewRequest.isLoading}>
      {children}
    </ReviewPopover>
  );
}

export const PaperworkReviewPopover = React.memo(PaperworkReviewPopoverComponent);
