import React, { useMemo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { DecimalTextField } from '@top-solution/microtecnica-mui';
import { PAPERWORK_FIELD_DATATYPE } from '../../../entities/Paperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { Currency } from '../../../pages/Currency/currency';
import { useReadCurrencyListQuery } from '../../../services/currency';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function ClosureValueInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);
  const { fieldValues } = usePaperworkFields();
  const { data: currencies } = useReadCurrencyListQuery();

  const value = useMemo(() => {
    if (fieldValue && fieldValue.value) return Number(fieldValue.value);
    return null;
  }, [fieldValue]);

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const getSelectedCurrencySymbol = (c: Currency['id']) => {
    if (!c || !currenciesById?.has(c)) return '-';
    return currenciesById.get(c)?.symbol;
  };

  const currencyValue = useMemo(() => {
    const value = Object.values(fieldValues).find(
      (v) => v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY
    )?.value;
    return Number(value);
  }, [fieldValues]);

  return (
    <DecimalTextField
      label={field.label}
      variant="outlined"
      fullWidth
      value={value ?? ('' as unknown as number)}
      onChange={(_, value) => updateValue(value?.toString() ?? undefined)}
      required={true}
      InputProps={{
        endAdornment: <InputAdornment position="end">{getSelectedCurrencySymbol(currencyValue)}</InputAdornment>,
      }}
    />
  );
}

export const ClosureValueInput = React.memo(ClosureValueInputComponent);
