import React, { forwardRef, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { PaperworkType } from '../../../entities/Paperwork';
import { useReadPaperworkTypeListQuery } from '../../../services/paperwork';

export type PaperworkTypeAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<PaperworkType['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'> & {
    filteredIdValues?: number[];
  };

function PaperworkTypeAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: PaperworkTypeAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, filteredIdValues, ...autocompleteProps } = props;
  const { data, isFetching, error: loadingError } = useReadPaperworkTypeListQuery();

  const map = useMemo(() => {
    const m: Record<string, PaperworkType> = {};
    if (data) data.forEach((s) => (m[s.id] = s));
    return m;
  }, [data]);

  return (
    <>
      <Grid md={4} xs={6}>
        <Autocomplete
          value={value}
          options={data?.map(({ id }) => id).filter((d) => filteredIdValues?.includes(d)) ?? []}
          getOptionLabel={(id) => {
            return map[id as string]?.name || 'unknown';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              error={Boolean(loadingError) || error}
              helperText={
                (loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined
              }
              fullWidth
              required={required}
              inputRef={ref}
              sx={sx}
            />
          )}
          loading={isFetching}
          {...autocompleteProps}
        />
      </Grid>
      <Grid md={8} xs={6}>
        {map[value as string]?.name.includes('AGT5') && (
          <Alert severity="info">
            {'La licenza AGT5 si applica solo ai programmi EFA e Tornado con spedizione verso una base militare'}
          </Alert>
        )}
        {map[value as string]?.name.includes('AGT3') && (
          <Alert severity="info">
            {
              'La licenza AGT3 si applica solo ai fornitori europei produttori (OE) e implica che il materiale esportato rientri in Microtecnica'
            }
          </Alert>
        )}
        {map[value as string]?.name.includes('LGP') && (
          <Alert severity="info">{'La LGP è applicabile solo per il programma EFA'}</Alert>
        )}
        {map[value as string]?.name.includes('Framework') &&
          !map[value as string]?.name.includes('Intermediazione') && (
            <Alert severity="info">
              <ul style={{ margin: 0, paddingLeft: 16 }}>
                <li>
                  Per OE/Spares: La licenza a fronte di Framework può essere richiesta solo per le vendite Spares/MRO!
                  Per vendite OE/CRM si può richiedere solo la licenza individuale
                </li>
                <li>Procurement: La licenza a fronte di framework non può essere richiesta per prodotti NPI</li>
              </ul>
            </Alert>
          )}
      </Grid>
    </>
  );
}

export const PaperworkTypeAutocomplete = forwardRef(
  PaperworkTypeAutocompleteComponent
) as typeof PaperworkTypeAutocompleteComponent;
