import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { useAuth } from '@top-solution/microtecnica-utils';
import { eccnColumn } from '../../../../../components/DataGrid/eccnColumn';
import { htsColumn } from '../../../../../components/DataGrid/htsColumn';
import { IncompleteRowsDatagrid } from '../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { materialDescriptionColumn } from '../../../../../components/DataGrid/materialDescriptionColumn';
import { partNumberColumn } from '../../../../../components/DataGrid/partNumberColumn';
import { useProgramColumn } from '../../../../../components/DataGrid/programColumn';
import { useSerniCodeColumn } from '../../../../../components/DataGrid/serniCodeColumn';
import { DeleteIcon, EditIcon, KeyboardOutlineIcon } from '../../../../../components/Icons';
import { Material } from '../../../../../entities/Material';
import { UserRole } from '../../../../../entities/User';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

interface SelectPartNumbersDatagridProps {
  materials: Material[];
  onDelete: (id: Material['id']) => void;
  onSelectMaterial: (material: Material['id']) => void;
  incompleteRows: Set<string>;
  withoutEquipment?: boolean;
  withoutPn?: boolean;
}

function SelectPartNumbersDatagridComponent(props: SelectPartNumbersDatagridProps) {
  const { materials, incompleteRows, onDelete, onSelectMaterial, withoutEquipment, withoutPn } = props;
  const auth = useAuth();

  const programColumn = useProgramColumn();
  const serniCodeColumn = useSerniCodeColumn();

  const dataGridColumns = useMemo(() => {
    let columns: Array<GridColDef | GridActionsColDef> = [];

    if (!withoutPn) {
      columns.push(partNumberColumn);
    }

    columns = columns.concat([materialDescriptionColumn, programColumn]);

    if (!withoutEquipment) {
      columns.push(serniCodeColumn);
    }

    columns = columns.concat([eccnColumn]);

    // if no PN there isn't any HTS
    if (!withoutPn) {
      columns.push(htsColumn);
    }

    columns.push({
      field: 'imported',
      type: 'boolean',
      headerName: 'Manuale',
      width: 40,
      valueGetter: (value) => !value,
      renderCell: ({ value }) => {
        return value === false ? null : (
          <Tooltip title="Inserito manualmente">
            <span>
              <KeyboardOutlineIcon />
            </span>
          </Tooltip>
        );
      },
    });

    columns.push({
      field: 'importReason',
      type: 'string',
      headerName: 'Giustificazione inserimento manuale',
      minWidth: 400,
    });

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 90,
      getActions: ({ row }: GridRowParams<Material>) => [
        !row.imported || auth.hasRole(UserRole.GT) || auth.hasRole(UserRole.ADMIN) ? (
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            onClick={() => onSelectMaterial(row.id)}
            label="Modifica materiale"
            title="Modifica materiale"
          />
        ) : (
          <Box width={30} />
        ),
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          onClick={() => onDelete(row.id)}
          label="Elimina materiale"
          title="Elimina materiale"
        />,
      ],
    });

    return columns;
  }, [auth, onDelete, onSelectMaterial, programColumn, serniCodeColumn, withoutEquipment, withoutPn]);

  return (
    <>
      <IncompleteRowsDatagrid
        initialState={{
          sorting: {
            sortModel: [{ field: partNumberColumn.field, sort: 'asc' }],
          },
        }}
        density="compact"
        rows={materials}
        columns={dataGridColumns}
        pinnedColumns={pinnedColumns}
        disableRowGrouping
        disableAggregation
        getRowClassName={(params) => (incompleteRows.has(params.row.id) ? 'incomplete' : '')}
        sx={{ border: 0 }}
      />
    </>
  );
}

export const SelectPartNumbersDatagrid = React.memo(SelectPartNumbersDatagridComponent);
