import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import PaperworkStatusSelect from '../../pages/Paperwork/PaperworkCreatePage/PaperworkStatusSelect';
import { usePatchPaperworkMutation } from '../../services/paperwork';

function UpdateStatusDialogComponent(props: { onClose: () => void }) {
  const [open, setOpen] = React.useState(false);

  const { paperwork } = usePaperwork();
  const [patchPaperwork, patchPaperworkRequest] = usePatchPaperworkMutation();
  const [status, setStatus] = useState(paperwork?.status.id);

  useEffect(() => {
    setStatus(paperwork?.status.id);
  }, [paperwork?.status.id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleChange = async () => {
    if (paperwork && status) {
      await patchPaperwork({ paperworkId: paperwork.id, statusId: status });
      setOpen(false);
      props.onClose();
    }
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>{'Change status'}</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="update-status-dialog-title"
        aria-describedby="update-status-dialog-description"
      >
        <DialogTitle id="update-status-dialog-title">{'Change paperwork status'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{'Seleziona il nuovo stato della pratica:'}</DialogContentText>
          <PaperworkStatusSelect
            value={status}
            onChange={(e) => setStatus(Number(e.target.value))}
            sx={{ marginBlockStart: 1 }}
          ></PaperworkStatusSelect>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Annulla
          </Button>
          <LoadingButton loading={patchPaperworkRequest.isLoading} onClick={handleChange} autoFocus>
            Conferma
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const UpdateStatusDialog = React.memo(UpdateStatusDialogComponent);
