import React from 'react';
import { usePaperworkReviews } from './usePaperworkReviews';
import { useSerniListUpdateReviews } from './useSerniListUpdateReviews';

interface ReviewGuardProps {
  editReview?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Renders the child(ren) if the user can currently see the paperwork revision, or make reviews.
 * Available in the Paperwork & SerniListUpdate flavors.
 */
function ReviewGuard(props: ReviewGuardProps & { showReviews: boolean; showEditReviews: boolean }) {
  const { children, editReview, showReviews, showEditReviews } = props;

  if (editReview) {
    if (showEditReviews) {
      return children;
    }

    return null;
  }

  if (showReviews) {
    return children;
  }

  return null;
}

export function PaperworkReviewGuard(props: ReviewGuardProps) {
  const { showReviews, showEditReviews } = usePaperworkReviews();

  return <ReviewGuard {...props} showReviews={showReviews} showEditReviews={showEditReviews} />;
}

export function SerniListUpdateReviewGuard(props: ReviewGuardProps) {
  const { showReviews, showEditReviews } = useSerniListUpdateReviews();

  return <ReviewGuard {...props} showReviews={showReviews} showEditReviews={showEditReviews} />;
}
