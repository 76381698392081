import React, { forwardRef, useMemo } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { SaleType } from '../../../entities/SaleType';
import { useReadSaleTypeListQuery } from '../../../services/saleType';

export type SaleTypeAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<SaleType['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'> & {
    filteredIdValues?: number[];
  };

function SaleTypeAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: SaleTypeAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, filteredIdValues, ...autocompleteProps } = props;
  const { data, isFetching, error: loadingError } = useReadSaleTypeListQuery();

  const map = useMemo(() => {
    const m: Record<string, SaleType> = {};
    if (data) data.forEach((s) => (m[s.id] = s));
    return m;
  }, [data]);

  return (
    <Autocomplete
      value={value}
      options={data?.map(({ id }) => id).filter((d) => filteredIdValues?.includes(d)) ?? []}
      getOptionLabel={(id) => {
        return map[id as string]?.name || 'unknown';
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          inputRef={ref}
          sx={sx}
        />
      )}
      loading={isFetching}
      {...autocompleteProps}
    />
  );
}

export const SaleTypeAutocomplete = forwardRef(SaleTypeAutocompleteComponent) as typeof SaleTypeAutocompleteComponent;
