import { Paperwork } from '../entities/Paperwork';
import {
  SerniListUpdate,
  SerniListUpdateSchema,
  SerniListUpdateType,
  SerniListUpdateTypeSchema,
  SerniListUpdatePriority,
  SerniListUpdatePrioritySchema,
  AddSerniListUpdateRequest,
  SerniListUpdateCompletedPagesSchema,
  SerniListUpdateCompletedPages,
  UpdateSerniListUpdateListPayload,
  SerniListUpdateStatus,
  SerniListUpdateStatusSchema,
  SerniListUpdateReview,
  SerniListUpdateReviewSchema,
  SerniListUpdateReviewUpdate,
} from '../entities/SerniListUpdate';
import { api, TAG_TYPES } from './baseApi';

const url = 'serni-list-update';

export const serniListUpdateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSerniListUpdateList: builder.query<Array<SerniListUpdate>, void>({
      query: () => ({ method: 'GET', url: `${url}` }),
      transformResponse: (data) => SerniListUpdateSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    readSerniListUpdate: builder.query<SerniListUpdate, { serniListUpdateId: SerniListUpdate['id'] }>({
      query: (params) => ({ url: `${url}/${params.serniListUpdateId}` }),
      transformResponse: (data) => SerniListUpdateSchema.parse(data),
      providesTags: (_res, _error, args) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: args.serniListUpdateId }],
    }),
    createSerniListUpdate: builder.mutation<{ id: number }, AddSerniListUpdateRequest>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    patchSerniListUpdate: builder.mutation<
      { id: number },
      {
        serniListUpdateId: SerniListUpdate['id'];
        statusId: SerniListUpdateStatus['id'];
        assignee?: Paperwork['assignee'];
        note?: string;
      }
    >({
      query: ({ serniListUpdateId, ...body }) => ({ url: `${url}/${serniListUpdateId}`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [
        { type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' },
        { type: TAG_TYPES.SERNI_LIST_UPDATE, id: req.serniListUpdateId },
        // { type: TAG_TYPES.SERNI_LIST_UPDATE_COMPLETED_PAGES, id: req.paperworkId },
        // { type: TAG_TYPES.SERNI_LIST_UPDATE_REVIEWS, id: req.paperworkId },
      ],
    }),
    readSerniListUpdateStatusList: builder.query<
      { list: SerniListUpdateStatus[]; map: Record<string, SerniListUpdateStatus> },
      void
    >({
      query: () => ({ url: 'paperwork-statuses' }), // FIXME:
      transformResponse: (data) => {
        const list = SerniListUpdateStatusSchema.array().parse(data);
        const map: Record<string, SerniListUpdateStatus> = {};
        list.forEach((plant) => (map[plant.id] = plant));
        return { list, map };
      },
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'STATUS_LIST' }],
    }),
    updateSerniListUpdate: builder.mutation<{ id: number }, UpdateSerniListUpdateListPayload>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: (_res, _error, args) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: args.id }],
    }),
    deleteSerniListUpdate: builder.mutation<void, { serniListUpdateId: SerniListUpdate['id'] }>({
      query: ({ serniListUpdateId }) => ({ url: `${url}/${serniListUpdateId}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    readSerniListUpdateTypeList: builder.query<Array<SerniListUpdateType>, void>({
      query: () => ({ url: 'serniListUpdate-types' }),
      transformResponse: (data) => SerniListUpdateTypeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'TYPE_LIST' }],
    }),
    readSerniListUpdatePriorityList: builder.query<Array<SerniListUpdatePriority>, void>({
      query: () => ({ url: 'paperwork-priorities' }),
      transformResponse: (data) => SerniListUpdatePrioritySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'PRIORITY_LIST' }],
    }),
    readSerniListUpdateCompletedPages: builder.query<
      SerniListUpdateCompletedPages,
      { serniListUpdateId: SerniListUpdate['id'] }
    >({
      query: ({ serniListUpdateId }) => ({ url: `${url}/${serniListUpdateId}/completed-pages` }),
      transformResponse: (data) => SerniListUpdateCompletedPagesSchema.parse(data),
      providesTags: (_res, _err, req) => [
        { type: TAG_TYPES.SERNI_LIST_UPDATE, id: `COMPLETED_PAGES_${req.serniListUpdateId}` },
      ],
    }),
    patchSerniListUpdateCompletedPages: builder.mutation<void, SerniListUpdateCompletedPages>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}/completed-pages`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: `COMPLETED_PAGES_${req.id}` }],
    }),
    readSerniListUpdateReviews: builder.query<SerniListUpdateReview[], { serniListUpdateId: SerniListUpdate['id'] }>({
      query: (params) => ({ url: `${url}/${params.serniListUpdateId}/reviews` }),
      transformResponse: (data) => SerniListUpdateReviewSchema.array().parse(data),
      providesTags: (_res, _error, args) => [{ type: TAG_TYPES.PAPERWORK_REVIEWS, id: args.serniListUpdateId }],
    }),
    updateSerniListUpdateReview: builder.mutation<{ id: number }, SerniListUpdateReviewUpdate>({
      query: ({ serniListId, ...body }) => ({ url: `${url}/${serniListId}/reviews`, method: 'PUT', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK_REVIEWS, id: req.serniListId }],
    }),
  }),
});

export const {
  useReadSerniListUpdateListQuery,
  usePatchSerniListUpdateMutation,
  useReadSerniListUpdateQuery,
  useDeleteSerniListUpdateMutation,
  useReadSerniListUpdateStatusListQuery,
  useReadSerniListUpdatePriorityListQuery,
  useCreateSerniListUpdateMutation,
  useUpdateSerniListUpdateMutation,
  useReadSerniListUpdateCompletedPagesQuery,
  useReadSerniListUpdateReviewsQuery,
  useUpdateSerniListUpdateReviewMutation,
} = serniListUpdateApi;
