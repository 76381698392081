import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert, { alertClasses } from '@mui/material/Alert';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FileDropzone } from '../../../components/FileDropzone';
import { PaperworkAttachment } from '../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE, addDraftIdToPaperworkFieldValue } from '../../../entities/Paperwork';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { useAttachments } from '../../../hooks/usePaperworkAttachments';
import { useCreateAttachmentMutation } from '../../../services/attachment';

interface AddSignedPaperworkDialogProps {
  paperworkAttachment?: PaperworkAttachment;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  onAdd: (signed: boolean) => void;
}

function AddSignedPaperworkDialogComponent(props: AddSignedPaperworkDialogProps) {
  const { disabled, buttonProps, onAdd, paperworkAttachment } = props;
  const [open, setOpen] = React.useState(false);
  const [uploadedAttachment, setUploadedAttachment] = useState<PaperworkAttachment['attachment']>();

  const { paperwork } = usePaperwork();
  const {
    createAttachment: createPaperworkAttachment,
    deleteAttachment,
    saveAttachments,
    discardOperations: discardAttachmentOperations,
  } = useAttachments();
  const { saveValues } = usePaperworkFields();
  const [createAttachment, createAttachmentRequest] = useCreateAttachmentMutation();
  const { route } = usePaperworkNavigation();

  const contextId = route?.context?.id ?? -1;

  const { contextFields, updateValue, discardOperations: discardFieldOperations } = usePaperworkFields();
  const [confirmed, setConfirmed] = useState(false);

  const paperworkDigitallySignedField = useMemo(
    () => contextFields.find((field) => field.datatype === PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED),
    [contextFields]
  );

  const AddAttachmentFormSchema = useMemo(
    () =>
      z.object({
        file: z
          .object(
            {
              name: z.string(),
            },
            { required_error: 'Caricare un documento' }
          )
          .passthrough(),
        paperworkDigitallySigned: z.boolean(),
      }),
    []
  );

  type AddAttachmentForm = z.infer<typeof AddAttachmentFormSchema>;

  const { control, handleSubmit, reset, formState } = useForm<AddAttachmentForm>({
    defaultValues: {
      file: undefined,
    },
    resolver: zodResolver(AddAttachmentFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(values: AddAttachmentForm) {
    if (uploadedAttachment && paperwork) {
      discardAttachmentOperations();

      if (paperworkAttachment) {
        deleteAttachment(paperworkAttachment.attachment.id);
      }

      if (uploadedAttachment) {
        createPaperworkAttachment({
          attachment: uploadedAttachment,
          savedInContextId: contextId,
          stakeholderIdList: [],
        });
      }
      if (values.paperworkDigitallySigned !== undefined) {
        updateValue(
          addDraftIdToPaperworkFieldValue({
            fieldDatatype: PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED,
            progIndex: 0,
            attachmentId: uploadedAttachment.id,
            value: values.paperworkDigitallySigned ? 'yes' : 'no',
          })
        );
      }

      // Save the paperwork attachments (attachmentId ⨯ stakeholderId)
      await saveAttachments();

      // Save the operations above
      saveValues();

      onAdd(values.paperworkDigitallySigned);

      // Close the dialog
      handleClose();
    }
  }

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} disabled={disabled} {...buttonProps}>
        {buttonProps?.children ?? 'Carica richiesta compilata'}
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
        fullWidth
        maxWidth="md"
        TransitionProps={{
          onExited: () => {
            reset();
            discardFieldOperations();
            discardAttachmentOperations();
            setUploadedAttachment(undefined);
            setConfirmed(false);
          },
        }}
      >
        <DialogTitle>Aggiungi documento</DialogTitle>
        <DialogContent>
          {createAttachmentRequest.error ? (
            <Alert severity="error">{JSON.stringify(createAttachmentRequest.error, null, 2)}</Alert>
          ) : null}
          <DialogContentText component="div" display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              name="file"
              render={({ field, fieldState: { error } }) => (
                <FileDropzone
                  error={error}
                  onChange={async (file) => {
                    field.onChange(file);

                    if (file) {
                      const createAttachmentResponse = await createAttachment({
                        file: file as unknown as File,
                      }).unwrap();
                      setUploadedAttachment(createAttachmentResponse);
                    } else {
                      setUploadedAttachment(undefined);
                    }
                  }}
                  value={field.value as unknown as File}
                />
              )}
            />
            {uploadedAttachment ? (
              <>
                {contextFields.length ? (
                  <>
                    <Grid container>
                      {paperworkDigitallySignedField ? (
                        <Grid key={paperworkDigitallySignedField.id} item xs={12} md={6} lg={6}>
                          <Controller
                            control={control}
                            name="paperworkDigitallySigned"
                            render={({ field }) => (
                              <FormControl key={PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED}>
                                <FormLabel id={paperworkDigitallySignedField.datatype}>
                                  {paperworkDigitallySignedField.label}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={paperworkDigitallySignedField.datatype}
                                  {...field}
                                  value={field.value === true ? 'yes' : field.value === false ? 'no' : undefined}
                                  onChange={(_, value) => {
                                    field.onChange(value === 'yes');
                                  }}
                                  sx={{
                                    gap: 3,
                                    paddingX: 2,
                                    paddingY: 1,
                                  }}
                                >
                                  <FormControlLabel value="yes" control={<Radio />} label="Si" />
                                  <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
            {paperworkAttachment ? (
              <Alert
                severity="warning"
                sx={{
                  marginTop: 2,
                  [`.${alertClasses.icon}`]: {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox color="secondary" checked={confirmed} onChange={(_, checked) => setConfirmed(checked)} />
                  }
                  label={`Confermo di voler sostituire la pratica esistente con quella appena caricata`}
                />
              </Alert>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {'Annulla'}
          </Button>
          <Button
            type="submit"
            disabled={!uploadedAttachment || !formState.isValid || (paperworkAttachment && !confirmed)}
          >
            {'Aggiungi'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const AddSignedPaperworkDialog = React.memo(AddSignedPaperworkDialogComponent);
