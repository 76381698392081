import React, { useMemo } from 'react';
import CurrencySelect from '../../Form/CurrencySelect/CurrencySelect';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function ClosureCurrencyInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  const value = useMemo(() => {
    if (fieldValue && fieldValue.value) return Number(fieldValue.value);
    return null;
  }, [fieldValue]);

  return (
    <CurrencySelect
      fullWidth
      value={value ?? ''}
      onChange={(e) => {
        updateValue(e.target.value.toString() ?? null);
      }}
      required={true}
    />
  );
}

export const ClosureCurrencyInput = React.memo(ClosureCurrencyInputComponent);
