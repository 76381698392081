import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DropDownMenu } from '../../../components/DropDownMenu';
import { usePaperworkReviews } from '../../../components/review/usePaperworkReviews';
import { PAPERWORK_STATUS, PAPERWORK_TYPE } from '../../../entities/Paperwork';
import { UserRole } from '../../../entities/User';
import { DataEntryPages, PaperworkPhases } from '../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkCompletedPagesQuery, useReadPaperworkStatusListQuery } from '../../../services/paperwork';
import { PaperworkDeleteDialog } from '../PaperworkDeleteDialog';
import { paperworkSection } from '../sections';
import { ChangeStatusConfirmDialog } from './ChangeStatusConfirmDialog';
import { PaperworkManageMenu } from './PaperworkManageMenu';

function PaperworkDetailPrimaryActionsComponent() {
  const { paperwork } = usePaperwork();
  const { data: paperworkStatus } = useReadPaperworkStatusListQuery();
  const { hasRole, isAdmin, username } = useAuth();
  const { allDataReviewed, hasRejectedReviews } = usePaperworkReviews();
  const { routes } = usePaperworkNavigation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const readPaperworkCompletedPagesQuery = useReadPaperworkCompletedPagesQuery(
    { paperworkId: paperwork?.id ?? 1 },
    { skip: !paperwork }
  );
  const dataEntryRoute = routes[PaperworkPhases.DataEntry];

  const allDataEntryCompleted = useMemo(() => {
    const completedPages = readPaperworkCompletedPagesQuery.data?.completedPages;

    if (!completedPages) {
      return false;
    }

    if (dataEntryRoute.pages[DataEntryPages.Materials].enabled) {
      if (!completedPages.materials?.dataEntryCompleted) {
        return false;
      }
    }
    if (dataEntryRoute.pages[DataEntryPages.Stakeholders].enabled) {
      if (!completedPages.stakeholders?.dataEntryCompleted) {
        return false;
      }
    }
    if (dataEntryRoute.pages[DataEntryPages.Attachments].enabled) {
      if (!completedPages.attachments?.dataEntryCompleted) {
        return false;
      }
    }
    if (dataEntryRoute.pages[DataEntryPages.ContractualTerms].enabled) {
      if (!completedPages['contractual-terms']?.dataEntryCompleted) {
        return false;
      }
    }
    return true;
  }, [dataEntryRoute.pages, readPaperworkCompletedPagesQuery.data?.completedPages]);

  if (!paperwork || !paperworkStatus) {
    return null;
  }

  function statusMenuItem(label: string, handleClose: () => void, disabled = false) {
    // eslint-disable-next-line react/display-name
    return (onClick: () => void) => (
      <MenuItem
        onClick={() => {
          handleClose();
          onClick();
        }}
        disabled={disabled}
      >
        {label}
      </MenuItem>
    );
  }

  if (paperwork.status.id === PAPERWORK_STATUS.DRAFT) {
    return (
      <>
        <Button onClick={() => setDeleteDialogOpen(true)} variant="contained" color="accent">
          {'Elimina richiesta in bozza'}
        </Button>
        <ChangeStatusConfirmDialog
          sourceStatus={paperwork.status}
          targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.WAITING]}
        >
          {(onClick) => (
            <Button variant="contained" color="accent" onClick={onClick} disabled={!allDataEntryCompleted}>
              {'Assegna pratica a GT'}
            </Button>
          )}
        </ChangeStatusConfirmDialog>
        <PaperworkDeleteDialog
          open={deleteDialogOpen}
          paperworkId={paperwork.id}
          handleClose={() => {
            setDeleteDialogOpen(false);
          }}
          handleConfirm={() => {
            navigate(paperworkSection.path);
          }}
        />
      </>
    );
  }

  if (paperwork.status.id === PAPERWORK_STATUS.WAITING) {
    if (username) {
      if (hasRole(UserRole.GT) || isAdmin) {
        return (
          <ChangeStatusConfirmDialog
            sourceStatus={paperwork.status}
            targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.TAKEN_CHARGE]}
            assignee={username}
          >
            {(onClick) => (
              <Button variant="contained" color="accent" onClick={onClick}>
                {'Prendi in carico'}
              </Button>
            )}
          </ChangeStatusConfirmDialog>
        );
      }
    }
  }

  if (paperwork.status.id === PAPERWORK_STATUS.TAKEN_CHARGE) {
    if (hasRole(UserRole.GT) || isAdmin) {
      return (
        <>
          <DropDownMenu
            direction="up"
            label="Gestisci stato pratica"
            slotProps={{
              button: {
                variant: 'contained',
                color: 'accent',
              },
              menu: {
                keepMounted: true,
              },
            }}
          >
            {(onClose) => (
              <>
                <ChangeStatusConfirmDialog
                  sourceStatus={paperwork.status}
                  targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.APPROVED]}
                >
                  {statusMenuItem('Approva', onClose, hasRejectedReviews || !allDataReviewed)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={paperwork.status}
                  targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.CONDITIONAL_APPROVED]}
                >
                  {statusMenuItem('Approva con riserva', onClose, hasRejectedReviews || !allDataReviewed)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={paperwork.status}
                  targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.REJECTED]}
                >
                  {statusMenuItem('Rifiuta', onClose)}
                </ChangeStatusConfirmDialog>
                <ChangeStatusConfirmDialog
                  sourceStatus={paperwork.status}
                  targetStatus={paperworkStatus?.map[PAPERWORK_STATUS.DRAFT]}
                >
                  {statusMenuItem('Tornata al mittente', onClose)}
                </ChangeStatusConfirmDialog>
              </>
            )}
          </DropDownMenu>
        </>
      );
    }
  }

  if (
    paperwork.status.id === PAPERWORK_STATUS.RELEASED &&
    paperwork.paperworkTemplate.paperworkType?.name !== PAPERWORK_TYPE.ChiusuraPratica &&
    paperwork.paperworkTemplate.paperworkType?.name !== PAPERWORK_TYPE.Proroga
  ) {
    return <PaperworkManageMenu />;
  }

  return null;
}

export const PaperworkDetailPrimaryActions = React.memo(PaperworkDetailPrimaryActionsComponent);
