import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { getFieldComponent } from '../../../../components/Fields/getFieldComponent';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { MaterialsStepLayout, MaterialsStepProps } from './MaterialsStepLayout';

function TotalQuantityStepComponent(props: MaterialsStepProps) {
  const { leftActions, onBack, onForward, forceEnableForwardButton } = props;
  const { paperwork } = usePaperwork();
  const { saveValues, patchPaperworkValuesRequest, operationsCount, contextFields, searchFieldValue } =
    usePaperworkFields();
  const { route } = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();
  const fieldValue = searchFieldValue({
    fieldDatatype: PAPERWORK_FIELD_DATATYPE.TOTAL_QUANTITY,
  });

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const primaryActions = (
    <>
      <BackButton onClick={onBack} sx={{ mr: 1 }} />
      <ForwardButton
        onClick={onForward}
        disabled={
          (!forceEnableForwardButton && operationsCount > 0) || !fieldValue?.value || Number(fieldValue.value!) < 1
        }
      />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={
            <SaveButton
              onClick={() => {
                saveValues();
              }}
              loading={patchPaperworkValuesRequest.isLoading}
              disabled={operationsCount === 0}
            />
          }
          leftActions={leftActions}
          operationsCount={operationsCount}
        />
      }
    >
      <PaperworkReviewGuard>
        <Stack direction="row">
          <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
            <ReviewChip review={review} size="medium" />
            <PaperworkReviewGuard editReview>
              <PaperworkReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
              </PaperworkReviewPopover>
            </PaperworkReviewGuard>
          </Stack>
        </Stack>
      </PaperworkReviewGuard>
      <Stack direction="column" alignItems="stretch" height="100%">
        <Grid container rowSpacing={3} columnSpacing={2} flex={1} paddingTop={3}>
          {contextFields.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={6}>
              {getFieldComponent(field)}
            </Grid>
          ))}
        </Grid>
      </Stack>
    </MaterialsStepLayout>
  );
}

export const TotalQuantityStep = React.memo(TotalQuantityStepComponent);
