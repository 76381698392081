import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert, { alertClasses } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { SERNI_LIST_UPDATE_STATUS, SerniListUpdateStatus } from '../../../entities/SerniListUpdate';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../hooks/useNotifications';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { usePatchSerniListUpdateMutation } from '../../../services/serniListUpdate';

export function ChangeStatusConfirmDialog(props: {
  children: (onClick: () => void) => React.ReactNode;
  sourceStatus: SerniListUpdateStatus;
  targetStatus?: SerniListUpdateStatus;
  assignee?: string;
}) {
  const { serniListUpdate } = useSerniListUpdate();
  const { children, sourceStatus, targetStatus, assignee } = props;
  const [patchSerniListUpdate, patchSerniListUpdateRequest] = usePatchSerniListUpdateMutation();
  const { pushPredefinedNotification } = useNotifications();

  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [comment, setComment] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirm() {
    if (serniListUpdate && targetStatus) {
      await patchSerniListUpdate({
        serniListUpdateId: serniListUpdate.id,
        statusId: targetStatus.id,
        assignee,
        note: comment,
      });
      pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_SAVE_SUCCESS);
      handleClose();
    }
  }

  if (!serniListUpdate || !targetStatus) {
    return;
  }

  const needsComment =
    targetStatus.id === SERNI_LIST_UPDATE_STATUS.REJECTED || targetStatus.id === SERNI_LIST_UPDATE_STATUS.RETURNED;

  return (
    <>
      {children(handleClickOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        TransitionProps={{
          onExited: () => setConfirmed(false),
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Conferma cambio di stato pratica'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" display="flex" gap={1}>
            <span>{`Lo stato della pratica #${serniListUpdate.id} verrà cambiato in`}</span>
            {/* <PaperworkStatusChip status={targetStatus.id} /> */}
            <span>{'.'}</span>
          </DialogContentText>
          {needsComment ? (
            <TextField
              multiline
              rows={3}
              label="Commento"
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{ marginY: 2 }}
            ></TextField>
          ) : null}
          <Alert
            severity="warning"
            sx={{
              marginTop: 2,
              [`.${alertClasses.icon}`]: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox color="secondary" checked={confirmed} onChange={(_, checked) => setConfirmed(checked)} />
              }
              label={`Confermo di voler cambiare lo stato pratica da "${sourceStatus.name}" a "${targetStatus.name}"`}
            />
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose} autoFocus>
            {'Annulla'}
          </Button>
          <LoadingButton
            color="warning"
            disabled={!confirmed || (needsComment && comment.length === 0)}
            loading={patchSerniListUpdateRequest.isLoading}
            onClick={handleConfirm}
          >
            {'Conferma'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
