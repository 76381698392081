import { z } from 'zod';

export const EquipmentSchema = z.object({
  id: z.string(),
  category: z.string().min(1),
  description: z.string().min(1),
  equipmentType: z.string().min(1),
  serniCode: z.string().min(1),
  paragraph: z.string().min(1),
  secrecyCategory: z.string().min(1),
  subParagraph: z.string().min(1),
});

// For Serni List Updates
export const EquipmentDraftSchema = z.object({
  equipment: z.string(),
  equipmentDescription: z.string(),
  equipmentProgram: z.string().min(1),
});

export type Equipment = z.infer<typeof EquipmentSchema>;
export type EquipmentDraft = z.infer<typeof EquipmentDraftSchema>;
