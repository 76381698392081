import { createListenerMiddleware, ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import debounce from 'lodash.debounce';
import { Paperwork, PAPERWORK_FIELD_DATATYPE, PAPERWORK_TYPE, PaperworkContext } from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import {
  AttachmentsSections,
  DataEntryPages,
  EndUsersSubsections,
  MaterialsSections,
  OrderSubsections,
  PaperworkPhases,
  StakeholdersSections,
} from '../../hooks/usePaperwork/paperworkNavigation';
import { paperworksApi } from '../../services/paperwork';
import { paperworkReviewApi } from '../../services/paperworkReview';
import { store } from '../store';

export const paperworkCompletionMiddleware = createListenerMiddleware();

async function checkCompletion(
  paperworkId: Paperwork['id'],
  state: ReturnType<typeof store.getState>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>
) {
  const { data: paperwork } = await paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state);
  const { data: templates } = await paperworksApi.endpoints.readPaperworkTemplatesList.select()(state);
  const { data: values } = await paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state);

  let materialsCompleted = true;
  let stakeholdersCompleted = true;
  let attachmentsCompleted = true;
  let contractualTermsCompleted = true;
  let extensionCompleted = true;
  let closureCompleted = true;

  if (paperwork && templates && values) {
    const paperworkTemplate = templates.find((template) => template.id === paperwork.paperworkTemplate.id);
    const materialContexts =
      paperworkTemplate?.paperworkContextList.filter(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Materials
      ) ?? [];
    const stakeholderContexts =
      paperworkTemplate?.paperworkContextList.filter(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Stakeholders
      ) ?? [];

    // ##### Materials #######################################################################################################
    const materialSelectionContext = materialContexts.find(
      (context) =>
        context.section === MaterialsSections.SelectPartNumbers ||
        context.section === MaterialsSections.SelectPartNumbersWithoutEquipment ||
        context.section === MaterialsSections.SelectPartNumbersWithoutPn
    );

    if (materialSelectionContext) {
      if (!paperwork.materialList || paperwork.materialList?.length === 0) {
        materialsCompleted = false;
      }
    }
    // ##### Materials #######################################################################################################

    // ##### Materials - Data entry ##########################################################################################
    const requiredMaterialFields =
      materialContexts
        .find((context) => context.section === MaterialsSections.MaterialsData)
        ?.fieldList?.filter((field) => field.isRequired) ?? [];

    for (const requiredMaterialField of requiredMaterialFields) {
      for (const material of paperwork.materialList ?? []) {
        const value = values?.find(
          (value) => value.materialId === material.id && value.fieldDatatype === requiredMaterialField.datatype
        );
        if (!value) {
          materialsCompleted = false;
        }
      }
    }
    // ##### Materials - Data entry ##########################################################################################

    // ##### Stakeholders - ShipTo ###########################################################################################
    const shipToContext = stakeholderContexts.find((context) => context.section === StakeholdersSections.ShipTo);

    if (shipToContext) {
      const shipToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
      );

      if (!shipToStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - ShipTo ###########################################################################################

    // ##### Stakeholders - OrderFrom ########################################################################################
    const orderFromContext = stakeholderContexts.find((context) => context.section === StakeholdersSections.OrderFrom);

    if (orderFromContext) {
      const orderFromStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.ORDER_FROM
      );

      if (!orderFromStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - OrderFrom ########################################################################################

    // ##### Stakeholders - BillTo ###########################################################################################
    const billToContext = stakeholderContexts.find((context) => context.section === StakeholdersSections.BillTo);

    if (billToContext) {
      const billToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.BILL_TO
      );

      if (!billToStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - BillTo ###########################################################################################

    // ##### Stakeholders - EndUser ##########################################################################################
    const endUserContext = stakeholderContexts.find((context) => context.section === StakeholdersSections.EndUser);
    const endUserMatchesValue = values.find(
      (value) => value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
    );

    if (endUserContext) {
      const endUseroStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.BILL_TO
      );

      if (!endUserMatchesValue) {
        stakeholdersCompleted = false;
      } else if (endUserMatchesValue.value === 'false' && !endUseroStakeholder) {
        stakeholdersCompleted = false;
      }
    }

    // ##### Stakeholders - EndUser ##########################################################################################

    // ##### Attachments #####################################################################################################
    const attachmentsSections =
      paperworkTemplate?.paperworkContextList.filter((context) => context.page === DataEntryPages.Attachments) ?? [];
    let eusSection: PaperworkContext | undefined = undefined;

    if (attachmentsSections.length > 0) {
      if (!paperwork.paperworkAttachmentList) {
        attachmentsCompleted = false;
      } else {
        for (const attachmentsSection of attachmentsSections) {
          // Exclude "parent" sections
          if (!attachmentsSection.subsection) {
            if (attachmentsSection.section === AttachmentsSections.Order) {
              continue;
            }
            if (attachmentsSection.section === AttachmentsSections.OtherDocuments) {
              continue;
            }
          }

          // Exclude EUS, it is a bit more complicate check
          if (attachmentsSection.section === AttachmentsSections.Eus) {
            eusSection = attachmentsSection;
            continue;
          }

          // Visura camerale:	"obbligatorio (per tutte - LGT / LGP / AGT3 / AGT5) / facoltativo per LGP"
          if (attachmentsSection.section === AttachmentsSections.VisuraCamerale) {
            if (!paperworkTemplate) {
              continue;
            }

            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Agt5) {
              continue;
            }

            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Lgp) {
              continue;
            }
          }

          if (attachmentsSection.section === AttachmentsSections.OtherDocuments) {
            if (!paperworkTemplate) {
              continue;
            }
            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Proroga) {
              continue;
            }
          }

          const sectionAttachment = paperwork.paperworkAttachmentList.find(
            (attachment) => attachment?.savedInContextId === attachmentsSection.id
          );
          if (!sectionAttachment) {
            attachmentsCompleted = false;
          }
        }
      }
    }

    if (endUserMatchesValue && eusSection) {
      if (endUserMatchesValue.value !== 'microtecnica') {
        const eusAttachment = paperwork.paperworkAttachmentList?.find(
          (attachment) => attachment.savedInContextId === eusSection.id
        );
        if (!eusAttachment) {
          attachmentsCompleted = false;
        }
      }
    }

    // ##### Attachments ####################################################################################################

    // ##### Contractual terms ##############################################################################################
    const contractualTermsSection = paperworkTemplate?.paperworkContextList.find(
      (context) => context.page === DataEntryPages.ContractualTerms
    );

    if (contractualTermsSection) {
      const requiredCTFields = contractualTermsSection.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredCTFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          contractualTermsCompleted = false;
        }
      }
    }
    // ##### Contractual terms #############################################################################################

    // ##### Extension ##############################################################################################
    const extensionSection = paperworkTemplate?.paperworkContextList.find(
      (context) => context.page === DataEntryPages.Extension
    );

    if (extensionSection) {
      const requiredExtensionFields = extensionSection.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredExtensionFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          extensionCompleted = false;
        }
      }
    }
    // ##### Extension #############################################################################################

    // ##### Closure ##############################################################################################
    const closureSection = paperworkTemplate?.paperworkContextList.find(
      (context) => context.page === DataEntryPages.Closure
    );

    if (closureSection) {
      const requiredFields = closureSection.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          closureCompleted = false;
        }
      }
    }
    // ##### Closure #############################################################################################

    // ##### Reviews #######################################################################################################

    const { data: reviews } = await paperworkReviewApi.endpoints.readPaperworkReviews.select({ paperworkId })(state);

    const materials = paperwork.materialList ?? [];
    const stakeholders = paperwork.stakeholderList ?? [];
    const attachments = paperwork.paperworkAttachmentList ?? [];

    let materialsReviewCompleted = false;
    let additionalEconomicConditionsCompleted = false;
    let stakeholdersReviewCompleted = false;
    let eusSkipInputSectionCompleted = false;
    let attachmentsReviewCompleted = false;
    let contractualTermsReviewCompleted = false;
    let extensionReviewCompleted = false;
    let closureReviewCompleted = false;

    const contractualTermsContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.ContractualTerms
      );
    const extensionContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Extension
      );
    const closureContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Closure
      );
    const eusSkipInputContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Stakeholders &&
          context.section === StakeholdersSections.EndUser &&
          context.subsection === EndUsersSubsections.SkipStakeholdersInputPanel
      );
    const orderApprovalContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Attachments &&
          context.section === AttachmentsSections.Order &&
          context.subsection === OrderSubsections.OrderApproval
      );
    const additionalEconomicConditionsContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Materials &&
          context.section === MaterialsSections.AdditionalEconomicConditions
      );

    const signingContextsIDs = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.filter((context) => context.phase === PaperworkPhases.PreparationSigning)
      .map((context) => context.id);

    if (reviews) {
      // Check every material has a review
      materialsReviewCompleted = materials.every((material) =>
        reviews.find((review) => review.materialId === material.id)
      );

      // Check every stakeholder has a review
      stakeholdersReviewCompleted = stakeholders.every((stakeholder) => {
        return reviews.find((review) => review.stakeholderId === stakeholder.id);
      });

      // Check every attachment has a review
      attachmentsReviewCompleted = attachments.every((paperworkAttachment) => {
        // Skip checking reviews on order approvals (reviews are done on orders)
        if (paperworkAttachment.savedInContextId === orderApprovalContext?.id) {
          return true;
        }

        // Ignore attachments added in Preparation & Signing phase
        if (signingContextsIDs && signingContextsIDs?.includes(paperworkAttachment.savedInContextId)) {
          return true;
        }

        return reviews.find((review) => review.attachmentId === paperworkAttachment.attachment.id);
      });

      // eslint-disable-next-line no-console
      console.log(
        '### debug, attachmentsReviewCompleted, attachments, reviews',
        attachmentsReviewCompleted,
        attachments,
        reviews
      );

      // Either the AEC section is not enabled for this paperwork, or it is reviewed
      additionalEconomicConditionsCompleted =
        !additionalEconomicConditionsContext ||
        Boolean(reviews?.find((review) => review.contextId === additionalEconomicConditionsContext.id));

      // Either the EUS skip input section is not enabled for this paperwork, or it is reviewed
      eusSkipInputSectionCompleted =
        !eusSkipInputContext || Boolean(reviews?.find((review) => review.contextId === eusSkipInputContext.id));

      // Either the contractual terms section is not enabled for this paperwork, or it is reviewed
      contractualTermsReviewCompleted =
        !contractualTermsContext || Boolean(reviews?.find((review) => review.contextId === contractualTermsContext.id));

      // Either the extension section is not enabled for this paperwork, or it is reviewed
      extensionReviewCompleted =
        !extensionContext || Boolean(reviews?.find((review) => review.contextId === extensionContext.id));

      // Either the closure section is not enabled for this paperwork, or it is reviewed
      closureReviewCompleted =
        !closureContext || Boolean(reviews?.find((review) => review.contextId === closureContext.id));
    }

    // ##### Reviews #######################################################################################################

    listenerApi.dispatch(
      paperworksApi.endpoints.patchPaperworkCompletedPages.initiate({
        id: paperworkId,
        completedPages: {
          materials: {
            dataEntryCompleted: materialsCompleted,
            reviewCompleted: materialsReviewCompleted && additionalEconomicConditionsCompleted,
          },
          stakeholders: {
            dataEntryCompleted: stakeholdersCompleted,
            reviewCompleted: stakeholdersReviewCompleted && eusSkipInputSectionCompleted,
          },
          attachments: {
            dataEntryCompleted: attachmentsCompleted,
            reviewCompleted: attachmentsReviewCompleted,
          },
          'contractual-terms': {
            dataEntryCompleted: contractualTermsCompleted,
            reviewCompleted: contractualTermsReviewCompleted,
          },
          extension: {
            dataEntryCompleted: extensionCompleted,
            reviewCompleted: extensionReviewCompleted,
          },
          closure: {
            dataEntryCompleted: closureCompleted,
            reviewCompleted: closureReviewCompleted,
          },
        },
      })
    );
  }
}

// Middlewares
const debouncedCheckCompletion = debounce(checkCompletion, 1000, { leading: false, trailing: true });

/**
 * Update the completion & review  status of the whole paperwork each time:
 * - The paperwork has been read
 * - The paperwork values have been read
 * - The paperwork reviews have been read
 */
paperworkCompletionMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperworkValues.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state).isLoading) {
      return;
    }

    checkCompletion(paperworkId, state, listenerApi);
  },
});

paperworkCompletionMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state).isLoading) {
      return;
    }

    debouncedCheckCompletion(paperworkId, state, listenerApi);
  },
});

paperworkCompletionMiddleware.startListening({
  matcher: paperworkReviewApi.endpoints.readPaperworkReviews.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state).isLoading) {
      return;
    }

    debouncedCheckCompletion(paperworkId, state, listenerApi);
  },
});
