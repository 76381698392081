import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useAuth } from '@top-solution/microtecnica-utils';
import { Review, ReviewSchema } from '../../entities/Review';
import { CheckCircleOutlineIcon, CloseCircleOutlineIcon } from '../Icons';

export interface ReviewPopoverProps {
  review: Review | undefined;
  children: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => React.ReactNode;
  onSubmitReview: (review: Review) => Promise<void>;
  isLoading: boolean;
}

function ReviewPopoverComponent(props: ReviewPopoverProps) {
  const { isLoading, review, onSubmitReview, children } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { username } = useAuth();
  const { control, handleSubmit, watch, formState, reset, setValue } = useForm<Review>({
    defaultValues: {
      author: username ?? '',
      comment: '',
      isApproved: review?.isApproved,
      updatedAt: new Date().toISOString(),
    },
    resolver: zodResolver(
      ReviewSchema.superRefine((value, ctx) => {
        if (value.isApproved === false && (!value.comment || value.comment.length < 1)) {
          ctx.addIssue({
            code: 'too_small',
            minimum: 1,
            inclusive: true,
            path: ['message'],
            type: 'string',
          });
        }
      })
    ),
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setValue('isApproved', review?.isApproved as unknown as boolean);
    setValue('comment', review?.comment ?? '');
  };

  const handleClose = () => {
    setAnchorEl(null);
    reset();
  };

  const onSubmit = async (review: Review) => {
    await onSubmitReview(review);
    handleClose();
  };

  const isApproved = watch('isApproved');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      {children(handleClick)}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            variant: 'elevation',
          },
        }}
      >
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} direction="column" padding={1} gap={1} width={400}>
          <Stack direction="row" gap={1}>
            <Controller
              control={control}
              name="isApproved"
              render={({ field }) => (
                <>
                  <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    color="success"
                    variant={isApproved ? 'contained' : 'outlined'}
                    onClick={() => {
                      field.onChange(true);
                      setValue('comment', '');
                    }}
                    sx={{ flex: 1, '&, &:hover': { boxShadow: 0 } }}
                  >
                    {'Approva'}
                  </Button>
                  <Button
                    startIcon={<CloseCircleOutlineIcon />}
                    color="error"
                    variant={isApproved === false ? 'contained' : 'outlined'}
                    onClick={() => field.onChange(false)}
                    sx={{ flex: 1, '&, &:hover': { boxShadow: 0 } }}
                  >
                    {'Rifiuta'}
                  </Button>
                </>
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="comment"
            render={({ field }) => (
              <TextField
                multiline
                rows={3}
                placeholder={'Inserire commento obbligatorio solo in caso di dato rifiutato'}
                {...field}
                disabled={isApproved}
              />
            )}
          />
          <Stack direction="row-reverse" gap={1}>
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!formState.isValid}
              loading={isLoading}
            >
              {'Salva'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
}

export const ReviewPopover = React.memo(ReviewPopoverComponent);
