import {
  Paperwork,
  PaperworkStatusSchema,
  PaperworkSchema,
  PaperworkStatus,
  PaperworkType,
  PaperworkTypeSchema,
  PaperworkPriority,
  PaperworkPrioritySchema,
  PaperworkTemplateSchema,
  PaperworkTemplate,
  SearchPaperworkListPayload,
  AddPaperworkRequest,
  PaperworkFieldValue,
  PaperworkFieldValueSchema,
  PatchPaperworkValueListRequest,
  PaperworkCompletedPages,
  PaperworkCompletedPagesSchema,
  PaperworkSendPecPayload,
} from '../entities/Paperwork';
import { api, TAG_TYPES } from './baseApi';

const url = 'paperworks';

export const paperworksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchPaperworkList: builder.query<Array<Paperwork>, SearchPaperworkListPayload>({
      query: (params) => ({ method: 'POST', url: `${url}/search`, body: params }),
      transformResponse: (data) => PaperworkSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'LIST' }],
    }),
    readPaperwork: builder.query<Paperwork, { paperworkId: Paperwork['id'] }>({
      query: (params) => ({ url: `${url}/${params.paperworkId}` }),
      transformResponse: (data) => PaperworkSchema.parse(data),
      providesTags: (_res, _error, args) => [{ type: TAG_TYPES.PAPERWORK, id: args.paperworkId }],
    }),
    patchPaperwork: builder.mutation<
      { id: number },
      { paperworkId: Paperwork['id']; statusId: PaperworkStatus['id']; assignee?: Paperwork['assignee']; note?: string }
    >({
      query: ({ paperworkId, ...body }) => ({ url: `${url}/${paperworkId}`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [
        { type: TAG_TYPES.PAPERWORK, id: 'LIST' },
        { type: TAG_TYPES.PAPERWORK, id: req.paperworkId },
        { type: TAG_TYPES.PAPERWORK_COMPLETED_PAGES, id: req.paperworkId },
        { type: TAG_TYPES.PAPERWORK_REVIEWS, id: req.paperworkId },
      ],
    }),
    deletePaperwork: builder.mutation<void, { paperworkId: Paperwork['id'] }>({
      query: ({ paperworkId }) => ({ url: `${url}/${paperworkId}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'LIST' }],
    }),
    readPaperworkStatusList: builder.query<{ list: PaperworkStatus[]; map: Record<string, PaperworkStatus> }, void>({
      query: () => ({ url: 'paperwork-statuses' }),
      transformResponse: (data) => {
        const list = PaperworkStatusSchema.array().parse(data);
        const map: Record<string, PaperworkStatus> = {};
        list.forEach((plant) => (map[plant.id] = plant));
        return { list, map };
      },
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'STATUS_LIST' }],
    }),
    readPaperworkTypeList: builder.query<Array<PaperworkType>, void>({
      query: () => ({ url: 'paperwork-types' }),
      transformResponse: (data) => PaperworkTypeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'TYPE_LIST' }],
    }),
    readPaperworkPriorityList: builder.query<Array<PaperworkPriority>, void>({
      query: () => ({ url: 'paperwork-priorities' }),
      transformResponse: (data) => PaperworkPrioritySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'PRIORITY_LIST' }],
    }),
    readPaperworkTemplatesList: builder.query<Array<PaperworkTemplate>, void>({
      // add isNotNull filter to get only templates with countryCategory
      query: () => ({ url: 'paperwork-templates' }),
      transformResponse: (data) => PaperworkTemplateSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'TEMPLATE_LIST' }],
    }),
    createPaperwork: builder.mutation<{ id: number }, AddPaperworkRequest>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'LIST' }],
    }),
    createExtensionPaperwork: builder.mutation<{ id: number }, { paperworkId: number }>({
      query: ({ paperworkId }) => ({
        url: `${url}/${paperworkId}/extension`,
        method: 'POST',
        body: { priorityId: 10 }, //TODO: priorityId should be dynamic?
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'LIST' }],
    }),
    createClosurePaperwork: builder.mutation<{ id: number }, { paperworkId: number }>({
      query: ({ paperworkId }) => ({
        url: `${url}/${paperworkId}/closure`,
        method: 'POST',
        body: { priorityId: 10 }, //TODO: priorityId should be dynamic?
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.PAPERWORK, id: 'LIST' }],
    }),
    readPaperworkValues: builder.query<Array<PaperworkFieldValue>, { paperworkId: Paperwork['id'] }>({
      query: ({ paperworkId }) => ({ url: `${url}/${paperworkId}/values` }),
      transformResponse: (data) => PaperworkFieldValueSchema.array().parse(data),
      // TODO: more precise cache invalidation
      providesTags: () => [{ type: TAG_TYPES.PAPERWORK_VALUE, id: `LIST` }],
    }),
    patchPaperworkValues: builder.mutation<{ id: number }, PatchPaperworkValueListRequest>({
      query: ({ paperworkId, ...body }) => ({ url: `${url}/${paperworkId}/values`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [
        { type: TAG_TYPES.PAPERWORK_VALUE, id: `LIST` },
        { type: TAG_TYPES.PAPERWORK, id: req.paperworkId },
        { type: TAG_TYPES.PAPERWORK_REVIEWS, id: req.paperworkId },
      ],
    }),
    readPaperworkCompletedPages: builder.query<PaperworkCompletedPages, { paperworkId: Paperwork['id'] }>({
      query: ({ paperworkId }) => ({ url: `${url}/${paperworkId}/completed-pages` }),
      transformResponse: (data) => PaperworkCompletedPagesSchema.parse(data),
      providesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK_COMPLETED_PAGES, id: req.paperworkId }],
    }),
    patchPaperworkCompletedPages: builder.mutation<void, PaperworkCompletedPages>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}/completed-pages`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK_COMPLETED_PAGES, id: req.id }],
    }),
    sendPec: builder.mutation<void, PaperworkSendPecPayload>({
      query: ({ paperworkId, ...body }) => ({ url: `${url}/${paperworkId}/send-pec`, method: 'POST', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK, id: req.paperworkId }],
    }),
  }),
});

export const {
  useReadPaperworkStatusListQuery,
  usePatchPaperworkMutation,
  useSearchPaperworkListQuery,
  useReadPaperworkQuery,
  useDeletePaperworkMutation,
  useReadPaperworkTypeListQuery,
  useReadPaperworkPriorityListQuery,
  useReadPaperworkTemplatesListQuery,
  useCreatePaperworkMutation,
  useReadPaperworkValuesQuery,
  usePatchPaperworkValuesMutation,
  useReadPaperworkCompletedPagesQuery,
  useSendPecMutation,
  useCreateExtensionPaperworkMutation,
  useCreateClosurePaperworkMutation,
} = paperworksApi;
