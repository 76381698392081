import React, { Fragment, useMemo } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CountryFlag, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { AlertCircleIcon } from '../../../../components/Icons';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { formatDateTime } from '../../../../utils/dates';
import { AttachmentDeleteDialog } from './AttachmentsDeleteDialog';
import { DownloadButton } from './DownloadButton';

interface AttachmentCardProps extends CardProps {
  paperworkAttachment: PaperworkAttachment;
}

function AttachmentCardComponent(props: AttachmentCardProps) {
  const { paperworkAttachment, ...cardProps } = props;
  const { paperwork } = usePaperwork();
  const { stakeholders } = useStakeholders();
  const { data: countries } = useReadCountriesQuery();
  const { reviews } = usePaperworkReviews();

  const { fieldValues, contextFields } = usePaperworkFields();

  // undefined      -> the field is not applicable for this context
  // 'yes' | 'no'   -> the field is applicable, and has a value
  const [originalAvailable, digitallySigned] = useMemo(() => {
    let originalAvailable: string | undefined = undefined;
    let digitallySigned: string | undefined = undefined;

    for (const contextField of contextFields) {
      if (contextField.datatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE) {
        originalAvailable = 'no';
      }
      if (contextField.datatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED) {
        digitallySigned = 'no';
      }
    }

    for (const fieldValue of Object.values(fieldValues)) {
      if (fieldValue.attachmentId === paperworkAttachment.attachment.id) {
        if (
          originalAvailable === 'no' &&
          fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE
        ) {
          originalAvailable = fieldValue.value ?? undefined;
        } else if (
          digitallySigned === 'no' &&
          fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED
        ) {
          digitallySigned = fieldValue.value ?? undefined;
        }
      }
    }

    return [originalAvailable, digitallySigned];
  }, [paperworkAttachment.attachment.id, contextFields, fieldValues]);

  const review = reviews.find(
    (review) => review.paperworkId === paperwork?.id && paperworkAttachment.attachment.id === review.attachmentId
  );

  return (
    <Card {...cardProps}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
            {paperworkAttachment.attachment.name}
          </Typography>
          <DownloadButton attachment={paperworkAttachment} />
          <AttachmentDeleteDialog attachment={paperworkAttachment} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="body2" flex={1}>
            {`Documento caricato il ${formatDateTime(new Date(paperworkAttachment.attachment.uploadDate))}`}
          </Typography>
          {digitallySigned === 'yes' ? (
            <Chip variant="outlined" color="primary" size="small" label="Firmato digitalmente" />
          ) : digitallySigned === 'no' ? (
            <Chip
              variant="outlined"
              color="warning"
              size="small"
              label="Firma digitale mancante"
              icon={<AlertCircleIcon />}
            />
          ) : null}
          {originalAvailable === 'yes' ? (
            <Chip variant="outlined" color="primary" size="small" label="Originale disponibile" />
          ) : digitallySigned === 'no' && originalAvailable === 'no' ? (
            <Chip
              variant="outlined"
              color="warning"
              size="small"
              label="Originale mancante"
              icon={<AlertCircleIcon />}
            />
          ) : null}
        </Stack>
      </CardContent>
      <Divider />
      {Object.values(stakeholders).length > 0 && (
        <List
          subheader={
            <ListSubheader
              component="div"
              sx={{
                lineHeight: 3,
                marginTop: 1,
              }}
            >
              {'Parti coinvolte'}
            </ListSubheader>
          }
        >
          {paperworkAttachment.stakeholderIdList?.map((stakeholderId) => {
            const stakeholder = Object.values(stakeholders).find((stakeholder) => stakeholder.id === stakeholderId);
            const country = stakeholder?.country ? countries?.byISO[stakeholder?.country] : undefined;

            let isMilitaryBase = false;
            if (stakeholder) {
              for (const [_, fieldValue] of Object.entries(fieldValues)) {
                if (fieldValue.stakeholderId === stakeholder.id) {
                  if (fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE) {
                    isMilitaryBase = fieldValue.value === 'true';
                  }
                }
              }
            }

            return (
              <Fragment key={stakeholderId}>
                <ListItem>
                  <Stack direction="column" flex={1} gap={0.75}>
                    <Stack direction="row">
                      <ListItemText primary={stakeholder?.name} sx={{ margin: 0 }} />
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={'0.8ch'}
                        sx={{
                          '& img': {
                            height: '0.8em',
                            borderRadius: 0.5,
                          },
                        }}
                      >
                        <CountryFlag countryCode={stakeholder?.country ?? ''} />
                        {countries?.byISO[stakeholder?.country ?? '']?.name}
                      </Stack>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <ListItemText secondary={stakeholder?.address} sx={{ flex: 1, margin: 0 }} />
                      {country?.isNato ? (
                        <Chip color="secondary" size="small" variant="outlined" label={'NATO'} />
                      ) : null}
                      {isMilitaryBase ? (
                        <Chip color="secondary" size="small" variant="outlined" label={'Forza armata'} />
                      ) : null}
                    </Stack>
                  </Stack>
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      )}
      <PaperworkReviewGuard>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
          <ReviewChip review={review} />
          <PaperworkReviewGuard editReview>
            <PaperworkReviewPopover
              paperworkReview={{
                attachmentId: paperworkAttachment.attachment.id,
              }}
              review={review}
            >
              {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
            </PaperworkReviewPopover>
          </PaperworkReviewGuard>
        </Stack>
      </PaperworkReviewGuard>
    </Card>
  );
}

export const AttachmentCard = React.memo(AttachmentCardComponent);
