import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { IncompleteRowsDatagrid } from '../../../../components/DataGrid/IncompleteRowsDatagrid';
import { stakeholderNameColumn } from '../../../../components/DataGrid/stakeholderAddressColumn';
import { stakeholderAddressColumn } from '../../../../components/DataGrid/stakeholderNameColumn';
import { useCountryColumn } from '../../../../components/DataGrid/useCountryColumn';
import { getFieldColDef } from '../../../../components/Fields/getFieldColDef';
import { EditIcon, KeyboardOutlineIcon } from '../../../../components/Icons';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { ReviewGridActionsCellItem } from '../../../../components/review/ReviewGridActionsCellItem';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import {
  addDraftIdToPaperworkFieldValue,
  PAPERWORK_FIELD_DATATYPE,
  PaperworkReview,
} from '../../../../entities/Paperwork';
import { Stakeholder } from '../../../../entities/Stakeholder';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { DeleteStakeholderDialog } from './DeleteStakeholderDialog';
import { StakeholderRow } from './StakeholderRow';

const pinnedColumns: GridPinnedColumnFields = { right: ['review', 'actions'] };

interface StakeholdersDatagridProps {
  rows: StakeholderRow[];
  onDelete: (id: Stakeholder['id']) => void;
  onSelectStakeholder: (stakeholder: Stakeholder['id']) => void;
  incompleteRows: Set<string>;
}

function StakeholdersDatagridComponent(props: StakeholdersDatagridProps) {
  const { rows, incompleteRows, onDelete, onSelectStakeholder } = props;

  const { contextFields, updateValue } = usePaperworkFields();
  const { showReviews } = usePaperworkReviews();

  const countryColumn = useCountryColumn();

  const dataGridColumns = useMemo(() => {
    const columns: Array<GridColDef | GridActionsColDef> = [
      {
        field: 'ptId',
        type: 'string',
        headerName: 'ID',
        minWidth: 180,
        flex: 0,
      },
      stakeholderNameColumn,
      stakeholderAddressColumn,
      {
        ...countryColumn,
        field: 'country',
        flex: 0,
      },
    ];

    for (const field of contextFields) {
      if (
        field.datatype !== PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO &&
        field.datatype !== PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_BILL_TO_MATCHES_SHIP_TO_OR_ORDER_FROM
      ) {
        columns.push(
          getFieldColDef(field, (newValue) => {
            updateValue(
              addDraftIdToPaperworkFieldValue({
                fieldDatatype: field.datatype,
                progIndex: 0,
                ...newValue,
              })
            );
          })
        );
      }
    }

    columns.push({
      field: 'imported',
      type: 'boolean',
      headerName: 'Manuale',
      width: 100,
      valueGetter: (value) => !value,
      renderCell: ({ value }) => {
        return value === false ? null : (
          <Tooltip title="Inserito manualmente">
            <span>
              <KeyboardOutlineIcon />
            </span>
          </Tooltip>
        );
      },
    });

    columns.push({
      field: 'importReason',
      type: 'string',
      headerName: 'Giustificazione inserimento manuale',
      minWidth: 400,
    });

    if (showReviews) {
      columns.push({
        field: 'review',
        type: 'boolean',
        headerName: 'Stato revisione',
        width: 130,
        renderCell: ({ row, value }: GridRenderCellParams<StakeholderRow, PaperworkReview>) =>
          row.id ? <ReviewChip review={value} /> : null,
      });
    }

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 120,
      getActions: ({ row }: GridRowParams<StakeholderRow>) => {
        const reviewActions = [];
        if (row.id) {
          reviewActions.push(
            <PaperworkReviewGuard key="review" editReview>
              <PaperworkReviewPopover
                paperworkReview={{
                  stakeholderId: row.id,
                }}
                review={row.review}
              >
                {(onCLick) => <ReviewGridActionsCellItem onClick={onCLick} highlight={!row.review} />}
              </PaperworkReviewPopover>
            </PaperworkReviewGuard>
          );
        }

        return [
          ...reviewActions,
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            onClick={() => onSelectStakeholder(row.id)}
            label="Modifica materiale"
            title="Modifica materiale"
            disabled={row.imported && !incompleteRows.has(row.id)}
          />,
          <DeleteStakeholderDialog key="delete" stakeholderId={row.id} onDelete={() => onDelete(row.id)} />,
        ];
      },
    });

    return columns;
  }, [countryColumn, showReviews, contextFields, updateValue, incompleteRows, onSelectStakeholder, onDelete]);

  return (
    <>
      <IncompleteRowsDatagrid
        density="standard"
        rows={rows}
        columns={dataGridColumns}
        pinnedColumns={pinnedColumns}
        disableRowGrouping
        disableAggregation
        getRowClassName={(params) => (incompleteRows.has(params.row.id) ? 'incomplete' : '')}
        sx={{ border: 0 }}
      />
    </>
  );
}

export const StakeholdersDatagrid = React.memo(StakeholdersDatagridComponent);
