import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { getFieldComponent } from '../../../../components/Fields/getFieldComponent';
import { Layout } from '../../../../components/Layout';
import { CancelButton } from '../../../../components/NavigationPanel/CancelButton';
import {
  BackButton,
  ForwardButton,
  NavigationButton,
  SaveButton,
} from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../../components/PageTitle';
import { PaperworkDebugMenu } from '../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../../hooks/useNotifications';
import { DataEntryPages, PaperworkPhases } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { paperworkRoutesConfiguration } from '../../../../hooks/usePaperwork/paperworkRouteConfiguration';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { PaperworkInfoPanel } from '../../PaperworkInfoPanel';

export default function PaperworkContractualTermsPage() {
  const navigate = useNavigate();
  const { detailsPath, routes, route } = usePaperworkNavigation();
  const { pushPredefinedNotification } = useNotifications();
  const { reviews } = usePaperworkReviews();
  const { paperwork } = usePaperwork();
  const pageTitle = paperwork
    ? paperworkRoutesConfiguration[PaperworkPhases.DataEntry].pages[DataEntryPages.ContractualTerms].title(paperwork)
    : '';

  const { contextFields, operationsCount, saveValues, discardOperations, fieldValues } = usePaperworkFields();

  const handleCancel = useCallback(async () => {
    await discardOperations();
    pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_DISCARD_SUCCESS);
  }, [discardOperations, pushPredefinedNotification]);

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const leftActions = (
    <>
      <NavigationButton component={Link} to={detailsPath}>
        {`Torna alla dashboard pratica`}
      </NavigationButton>
    </>
  );

  const isEveryFieldFilled = useMemo(() => {
    for (let index = 0; index < contextFields.length; index++) {
      const fieldValue = Object.values(fieldValues).find((v) => v.fieldDatatype === contextFields[index].datatype);
      if (fieldValue?.value !== undefined) continue;
      else return false;
    }
    return true;
  }, [contextFields, fieldValues]);

  return (
    <Layout
      title={pageTitle}
      header={<PageTitle pageTitle={pageTitle} rightItems={<PaperworkInfoPanel />} />}
      maxWidth={false}
    >
      <PaperworkReviewGuard>
        <Stack direction="row">
          <Divider />
          <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
            <ReviewChip review={review} />
            <PaperworkReviewGuard editReview>
              <PaperworkReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
              </PaperworkReviewPopover>
            </PaperworkReviewGuard>
          </Stack>
        </Stack>
      </PaperworkReviewGuard>
      <Stack direction="column" alignItems="stretch" height="100%">
        <Box flex={1}>
          <Grid container rowSpacing={3} columnSpacing={2} flex={1} paddingTop={3}>
            {contextFields.map((field) => (
              <Grid key={field.id} item xs={12} md={6} lg={4}>
                {getFieldComponent(field)}
              </Grid>
            ))}
          </Grid>
        </Box>
        <NavigationPanel
          primaryActions={
            <>
              <BackButton
                onClick={() =>
                  navigate(`../${routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].path}`)
                }
                sx={{ mr: 1 }}
              />
              <ForwardButton
                onClick={async () => {
                  navigate(detailsPath);
                }}
                disabled={operationsCount > 0 || !isEveryFieldFilled}
              />
            </>
          }
          secondaryActions={[
            <CancelButton key="cancel" onConfirm={handleCancel} disabled={operationsCount === 0} />,
            <SaveButton
              key="save"
              onClick={async () => {
                if (operationsCount > 0) {
                  saveValues();
                }
              }}
              disabled={operationsCount === 0}
            />,
          ]}
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={operationsCount}
        />
      </Stack>
    </Layout>
  );
}
