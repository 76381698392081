import React from 'react';
import { DetailSection } from '../../../components/DetailSection';
import { SERNI_LIST_UPDATE_STATUS } from '../../../entities/SerniListUpdate';
import { DataEntryPages, SerniListUpdatePhases } from '../../../hooks/useSerniListUpdate/serniListUpdateNavigation';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useSerniListUpdateNavigation } from '../../../hooks/useSerniListUpdate/useSerniListUpdateNavigation';
import { useReadSerniListUpdateCompletedPagesQuery } from '../../../services/serniListUpdate';
import { WizardList } from '../../Paperwork/PaperworkDetail/WizardList';
import { WizardListItem } from '../../Paperwork/PaperworkDetail/WizardListItem';

function NavigationComponent() {
  const { routes } = useSerniListUpdateNavigation();
  const { serniListUpdate } = useSerniListUpdate();
  const { data: completedPages } = useReadSerniListUpdateCompletedPagesQuery(
    { serniListUpdateId: serniListUpdate?.id ?? 1 },
    { skip: !serniListUpdate }
  );

  const configurationRoutes = routes[SerniListUpdatePhases.Configuration];
  const dataEntryRoute = routes[SerniListUpdatePhases.DataEntry];

  const mode =
    serniListUpdate && serniListUpdate.status.id < SERNI_LIST_UPDATE_STATUS.TAKEN_CHARGE ? 'DATA_ENTRY' : 'REVIEW';

  const dataEntryCompleted = completedPages?.completedPages?.dataEntry;

  return (
    <>
      <DetailSection sectionTitle={configurationRoutes.title}>
        <WizardList>
          <WizardListItem
            text={configurationRoutes.title}
            completed
            reviewCompleted={false}
            mode={mode}
            linkTo={configurationRoutes.absolutePath}
          />
        </WizardList>
      </DetailSection>
      <DetailSection sectionTitle={dataEntryRoute.title}>
        <WizardList>
          <WizardListItem
            text={dataEntryRoute.pages[DataEntryPages.DataEntry].title}
            linkTo={dataEntryRoute.pages[DataEntryPages.DataEntry].absolutePath}
            commentCount={0}
            reviewCompleted={dataEntryCompleted?.reviewCompleted ?? false}
            mode={mode}
            completed={dataEntryCompleted?.dataEntryCompleted ?? false}
          />
        </WizardList>
      </DetailSection>
    </>
  );
}

export const Navigation = React.memo(NavigationComponent);
