import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkReviewPopover } from '../../../../components/review/PaperworkReviewPopover';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { PaperworkReviewGuard } from '../../../../components/review/ReviewGuard';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import AdditionalEconomicConditions from './AdditionalEconomicConditions/AdditionalEconomicConditions';
import { MaterialsStepLayout, MaterialsStepProps } from './MaterialsStepLayout';

function AdditionalEconomicConditionsStepComponent(props: MaterialsStepProps) {
  const { leftActions, onBack, onForward, forceEnableForwardButton } = props;
  const { paperwork } = usePaperwork();
  const { saveValues, patchPaperworkValuesRequest, operationsCount } = usePaperworkFields();
  const { route } = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const primaryActions = (
    <>
      <BackButton onClick={onBack} sx={{ mr: 1 }} />
      <ForwardButton onClick={onForward} disabled={!forceEnableForwardButton && operationsCount > 0} />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={
            <SaveButton
              onClick={() => {
                saveValues();
              }}
              loading={patchPaperworkValuesRequest.isLoading}
              disabled={operationsCount === 0}
            />
          }
          leftActions={leftActions}
          operationsCount={operationsCount}
        />
      }
    >
      <PaperworkReviewGuard>
        <Stack direction="row">
          <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
            <ReviewChip review={review} size="medium" />
            <PaperworkReviewGuard editReview>
              <PaperworkReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
              </PaperworkReviewPopover>
            </PaperworkReviewGuard>
          </Stack>
        </Stack>
      </PaperworkReviewGuard>
      <AdditionalEconomicConditions />
    </MaterialsStepLayout>
  );
}

export const AdditionalEconomicConditionsStep = React.memo(AdditionalEconomicConditionsStepComponent);
